/**
 * @Author: Wen Xin <wenxin3262>
 * @Date:   01/09/17
 * @Copyright: Copyright © 2017 Aira Tech Corp. All rights reserved.
 */

import axios from 'axios';
import * as Sentry from '@sentry/vue';
import AiraError from '../utils/AiraError';

function checkStatus(response) {
  const path = response.config.url.replace(response.config.baseURL, '');
  console.log(`%cresponse: ${response.config.method.toUpperCase()} ${path}`, 'background: blue; color: white;', response.status);
  if (response.data.response && response.data.response.status === 'FAILURE') {
    const error = new Error(response.data.response.errorMessage || 'Platform Status Error');
    error.code = response.data.response.errorCode;
    return Promise.reject(error);
  }
  return response.data;
}
function errorLogger(error) {
  if (error.code === 'ECONNABORTED') {
    console.error(`TIMEOUT happend on url ${error.config.url}`);
  }
  console.error(error);
  Sentry.withScope((scope) => {
    if (process.env.NODE_ENV === 'production') {
      const response = error.response ? error.response : null;
      if (response) {
        scope.setExtra('response', error.response);
      }
      scope.setLevel('error');
      const err = new AiraError(error);
      if (err.errorCode !== 'SEC-001') {
        Sentry.captureMessage(`${err.status} - [${err.errorCode}] ${err.errorMessage}.`);
      }
    }
  });
  return Promise.reject(error);
}
function logger(request) {
  console.log(`%c${request.method.toUpperCase()} ${request.url}`, 'background: blue; color: white;', request.params, request.data);
  request.headers['X-Trace-Id'] = Math.floor((Math.random() * 9007199254740991) + 1); // eslint-disable-line no-param-reassign
  return request;
}
// debug
// function sleeper(ms) {
//   return (x) => {
//     console.log('sleeper');
//     return new Promise(resolve => setTimeout(() => resolve(x), ms));
//   };
// }

class APIClient {
  constructor() {
    this.client = axios.create({
      baseURL: process.env.VUE_APP_PLATFORM_URL,
      timeout: 30000,
    });
    this.client.defaults.headers.common['X-API-Key'] = process.env.VUE_APP_X_API_Key;
    this.client.defaults.headers.common['X-Client-Id'] = 'service_portal';
    this.client.defaults.headers.common['Content-Type'] = 'application/json';
    this.client.defaults.headers.common.Accept = 'application/json';
    this.client.interceptors.response.use(checkStatus, errorLogger);
    this.client.interceptors.request.use(logger);
  }

  // ===== UTILITIES =====
  setSsoToken(token) {
    this.client.defaults.headers.common['X-Aira-Token'] = token;
  }

  setRecaptchaToken(token) {
    this.client.defaults.headers.common['X-Recaptcha-Token'] = token;
  }

  // ===== USER =====
  /**
   * depending on the authProvider, this will exchange a password for the X-Aira-Token
   * http://dev-api.aira.io/#!/login
   * @param {Object} - login(email), loginFrom(SSP), password(token), authProvider(google)
   */
  login(payload) {
    return this.client.post('/user/login', payload);
  }

  postVerifyEmail(payload) {
    return this.client.post('/ssp/verify/email', payload);
  }

  postVerifyEmailConfirm(payload) {
    return this.client.post('/ssp/verify/email/confirm', payload);
  }

  /**
   * retrieve user's login info via token and returns 200 else return 401 or 400 if token not in header
   * must have valid token in order for call to succeed
   * http://dev-api.aira.io/#!/login/validateToken
   */
  validateUserToken() {
    return this.client.get('/user/login/validate-token');
  }

  // PASSWORD
  passwordReset(payload) {
    return this.client.post('/password', payload);
  }

  passwordResetVerifyCode(payload) {
    return this.client.get(`/password?code=${payload.code}`);
  }

  updatePassword(payload) {
    return this.client.put('/password', payload);
  }

  // JOIN ACCOUNT
  joinAccountVerifyCode(payload) {
    return this.client.put('/account/sharing/invite', payload);
  }

  joinAccountCreateUser(payload) {
    return this.client.post('/website/account/createAndJoin', payload);
  }

  // ===== PROFILE =====
  // Check if phone number belongs to guest
  verifyPhone(payload) {
    return this.client.post('/internal/validate/number', payload);
  }

  // Check if an email is in use
  isEmailInUse(email) {
    return this.client.get(`/validate/email/${email}`);
  }

  // get user profile
  getUserProfile(userId) {
    return this.client.get(`/user/${userId}`);
  }

  getUserProperty(userId, key) {
    return this.client.get(`/user/${userId}/property/${key}/value`);
  }

  updateEmail(email) {
    return this.client.put('/user/me/email', email);
  }

  updatePhone(payload) { // { phone, countryCode }
    return this.client.put('/user/me/phone', payload);
  }

  reverifyPhone(phone) {
    return this.client.post('/user/me/phone/verify', phone);
  }

  verificationConfirm(payload) {
    return this.client.post('/ssp/reset/confirm', payload);
  }

  verificationResend(payload) {
    return this.client.post('/ssp/reset/verify', payload);
  }

  userVerify(payload) {
    return this.client.post('/user/me/verify', payload);
  }

  userVerifyConfirm(auth) {
    return this.client.post('/user/me/verify/confirm', auth);
  }

  getUserBilling() {
    return this.client.get('/user/me/billing-info');
  }

  putUserBilling(payload) {
    return this.client.put('/user/me/billing-info', payload);
  }

  // ===== REFERRAL =====
  // Obtain first name of person who referred the user signing up for Aira
  getReferralInfo(referral) {
    return this.client.get(`/user/referral/${referral}`);
  }

  // ===== SIGNUP, UPGRADES, PURCHASES =====
  // new internal & external user signup
  signupVerify(payload) {
    return this.client.post('/ssp/verify', payload);
  }

  signupVerifyConfirm(auth) {
    return this.client.post('/ssp/verify/confirm', auth);
  }

  doSignup(payload) {
    return this.client.post('/order/explorer', payload);
  }

  doInternalSignup(payload) {
    return this.client.post('/internal/explorer', payload);
  }

  doGuestSignup(payload) {
    return this.client.post('/internal/guest', payload);
  }

  doGuestSignupBasic(payload) {
    return this.client.post('/order/guest/basic', payload);
  }

  // in app guest upgrade (requires X-AIRA-TOKEN)
  doGuestUpgrade(payload) {
    return this.client.post('/order/guest/upgrade', payload);
  }

  // internal guest upgrade (requires Basic auth)
  doInternalGuestUpgrade(userId, payload) {
    return this.client.post(`/internal/guest/upgrade/${userId}`, payload);
  }

  doExplorerUpgrade(payload) {
    return this.client.put('/order/primary', payload);
  }

  doAddOnPurchase(payload) {
    return this.client.post('/order/minute-addon', payload);
  }

  // ===== PLANS =====
  getPlan(planCode, operatingCountry) {
    return this.client.get(`/plan?planCode=${planCode}&operatingCountry=${operatingCountry}`);
  }

  // in app guest plans (requires X-AIRA-TOKEN)
  getOrderOptions() {
    return this.client.get('/order/options');
  }

  // internal plans (requires Basic auth)
  getInternalPlans(operatingCountry, isEmployee) {
    return this.client.get(`/internal/plan?operatingCountry=${operatingCountry}${isEmployee ? '&employee=true' : ''}`);
  }

  // Get list of business accounts
  // eslint-disable-next-line
  async getBusinessAccounts() {
    try {
      return await this.getBusinessAccountsHandler(0);
    } catch (error) {
      console.error(error);
    }
  }
  // eslint-disable-next-line
  async getBusinessAccountsHandler(pg) {
    try {
      const response = await this.client.get(`/internal/account/business?pg=${pg}`);
      const results = response.payload;
      if (response.response.hasMore) {
        const hasMore = await this.getBusinessAccountsHandler(pg + 1);
        return results.concat(hasMore);
      } else {
        return results;
      }
    } catch (error) {
      console.error(error);
    }
  }

  // ===== ORDER =====
  // check if couponCode is valid
  validateCoupon(couponCode) {
    return this.client.get(`/website/coupon/${couponCode}`);
  }

  // get order summary
  getOrderPreview(payload) {
    return this.client.post('/order/explorer/preview', payload);
  }

  // get internal order summary
  getInternalOrderPreview(payload) {
    return this.client.post('/internal/explorer/preview', payload);
  }

  // get in app guest upgrade order summary (requires X-AIRA-TOKEN)
  getGuestUpgradeOrderPreview(payload) {
    return this.client.post('/order/guest/upgrade/preview', payload);
  }

  // get internal guest upgrade order summary (requires Basic auth)
  getInternalGuestUpgradeOrderPreview(userId, payload) {
    return this.client.post(`/internal/guest/upgrade/preview/${userId}`, payload);
  }

  getExplorerUpgradeOrderPreview(payload) {
    return this.client.post('/order/explorer/upgrade/preview', payload);
  }

  // get in app add on order summary (requires X-AIRA-TOKEN)
  getAddOnOrderPreview(payload) {
    return this.client.post('/order/minute-addon/preview', payload);
  }

  /**
   * ===== USER PROPERTIES =====
   * @url http://dev-api.aira.io/#/user-property
   */

  /**
   * Update Property Value
   * @url http://dev-api.aira.io/#!/user-property/updatePropertyValue
   * @endpoint PUT /user/{user_id}/property/{key}/value
   * Will delete all other user property values and take this list as new values
   *
   * @param {String} userId
   * @param {String} propertyKey
   * @param {Array} payload
      [
        {
          "value": "propertyValue",
          "children": {
            "propertyKey.childPropertyKey": [
              {
                "value": ...,
                "children": {[...]}
              }
            ]
          }
        },
        {...}
      ]
   */
  updatePropertyValue(userId, propertyKey, payload) {
    return this.client.put(`/user/${userId}/property/${propertyKey}/value`, payload);
  }

  // ===== RESELLER =====

  // http://dev-api.aira.io/#!/internal/postResellerAccount
  postResellerAccount(payload) {
    return this.client.post('/internal/reseller', payload);
  }

  // http://dev-api.aira.io/#!/ssp/getResellerPlans
  getResellerPlans() {
    return this.client.get('/ssp/reseller/plans');
  }

  resellerVerifyPhone(payload) {
    return this.client.post('/ssp/reseller/verify/number', payload);
  }

  getResellerOrderPreview(payload) {
    return this.client.post('/ssp/reseller/order/preview', payload);
  }

  postResellerOrder(payload) {
    return this.client.post('/ssp/reseller/order', payload);
  }

  // ===== ACCESS PARTNER =====
  postAccessPartner(payload) {
    return this.client.post('/ssp/access-partner', payload);
  }

  postAccessPartnerOrder(payload) {
    return this.client.post('/ssp/access-partner/order', payload);
  }

  postAccessPartnerOrderSummary(payload) {
    return this.client.post('/ssp/access-partner/order/preview', payload);
  }

  // ===== GIFT =====
  getGiftCardOptions() {
    return this.client.get('/ssp/gift-card/options');
  }

  getGiftCardOrderSummary(payload) {
    return this.client.post('/ssp/gift-card/order/preview', payload);
  }

  postGiftCardOrder(payload) {
    return this.client.post('/ssp/gift-card/order', payload);
  }

  // ===== REPORT =====
  getReportData(serviceId) {
    return this.client.get(`/portal/report/${serviceId}`);
  }

  getChatSession(chatId) {
    return this.client.get(`/chat/${chatId}`);
  }

  flagChatSession(chatId, flagged) {
    return this.client.put(`/chat/${chatId}`, { flagged });
  }

  getChatMessageFailureModes() {
    return this.client.get('/chat/message/failure-modes');
  }

  deletePhoto(photoId) {
    return this.client.delete(`/files/${photoId}`);
  }

  // ===== AGENT ACTIVITY =====
  getInitialAgentActivity() {
    return this.client.get('portal/activity/agent');
  }

  getCallThrottlingStatus() {
    return this.client.get('service-request/throttling');
  }
}

const instance = new APIClient();
Object.freeze(instance);

export default instance;
