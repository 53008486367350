<template>
  <div id="page-header">
    <div class="page-container">
      <div class="header-item float-left">
        <img
          src="../../assets/Logo@2x.png"
          alt="aira logo"
        >
      </div>
      <div class="header-item float-right">
        <b-link
          aria-label="click to call an Aira customer care at 1-800-835-1934"
          href="tel:1-800-835-1934"
        >
          1-800-835-1934
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
};
</script>

<style lang="scss" scoped>
#page-header {
  height: 90px;
  .page-container {
    height: inherit;
    margin: 0 4vw 0 4vw;
  }
  .header-item {
    display: inline-block;
    line-height: 90px;
    & img {
      height: 40px;
    }
  }
}
</style>
