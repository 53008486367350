import { initializeApp } from '@firebase/app';
import { getRemoteConfig, isSupported } from '@firebase/remote-config';
// Initialize Firebase

let a = null;
let rc = null;
try {
  // Initialize Firebase
  const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG);
  a = initializeApp(firebaseConfig);

  // Initialize Remote Config
  if (isSupported()) {
    rc = getRemoteConfig(a);
  } else {
    console.error('Unable to get Remote Config');
  }
} catch (error) {
  console.error('Error initializing Firebase or Remote Config:', error);
  // app and remoteConfig remain null
}
const app = a;
const remoteConfig = rc;

export { app, remoteConfig };
