// ===== ACCESS =====
export const SET_DAYS_OPTIONS = 'SET_DAYS_OPTIONS';
export const SET_DAYS_SELECTED = 'SET_DAYS_SELECTED';
export const UPDATE_START_DATE = 'UPDATE_START_DATE';
export const UPDATE_START_DAY = 'UPDATE_START_DAY';
export const UPDATE_START_MONTH = 'UPDATE_START_MONTH';
export const UPDATE_START_YEAR = 'UPDATE_START_YEAR';
export const UPDATE_START_HOUR = 'UPDATE_START_HOUR';
export const UPDATE_SITE_FIRST_NAME = 'UPDATE_SITE_FIRST_NAME';
export const UPDATE_SITE_LAST_NAME = 'UPDATE_SITE_LAST_NAME';
export const UPDATE_SITE_ADDRESS1 = 'UPDATE_SITE_ADDRESS1';
export const UPDATE_SITE_COUNTRY = 'UPDATE_SITE_COUNTRY';
export const UPDATE_SITE_CITY = 'UPDATE_SITE_CITY';
export const UPDATE_SITE_STATE = 'UPDATE_SITE_STATE';
export const UPDATE_SITE_ZIP = 'UPDATE_SITE_ZIP';

// ===== ADDRESS =====
export const TOGGLE_SAME_BILLING_SHIPPING = 'TOGGLE_SAME_BILLING_SHIPPING'; // external signup: same billing as shipping
export const TOGGLE_SAME_SHIPPING_BILLING = 'TOGGLE_SAME_SHIPPING_BILLING'; // same shipping as billing
export const UPDATE_ADDRESS1 = 'UPDATE_ADDRESS1';
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const UPDATE_CITY = 'UPDATE_CITY';
export const UPDATE_STATE = 'UPDATE_STATE';
export const UPDATE_ZIP = 'UPDATE_ZIP';
export const UPDATE_SHIPPING_ADDRESS1 = 'UPDATE_SHIPPING_ADDRESS1';
export const UPDATE_SHIPPING_ADDRESS2 = 'UPDATE_SHIPPING_ADDRESS2';
export const UPDATE_SHIPPING_COUNTRY = 'UPDATE_SHIPPING_COUNTRY';
export const UPDATE_SHIPPING_CITY = 'UPDATE_SHIPPING_CITY';
export const UPDATE_SHIPPING_STATE = 'UPDATE_SHIPPING_STATE';
export const UPDATE_SHIPPING_ZIP = 'UPDATE_SHIPPING_ZIP';

// ===== APP =====
export const TOGGLE_DEBUG = 'TOGGLE_DEBUG';
export const PUSH_ERRORS = 'PUSH_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const PUSH_WARNING = 'PUSH_WARNING';
export const PUSH_NOTICE = 'PUSH_NOTICE';
export const CLEAR_NOTICE = 'CLEAR_NOTICE';
export const SET_NEED_REFRESH = 'SET_NEED_REFRESH';

// ===== GIFT =====
export const SET_GIFT_CARD_OPTIONS = 'SET_GIFT_CARD_OPTIONS';
export const SET_GIFT_CARD_SELECTED = 'SET_GIFT_CARD_SELECTED';
export const UPDATE_GIFTEE_FIRST_NAME = 'UPDATE_GIFTEE_FIRST_NAME';
export const UPDATE_GIFTEE_LAST_NAME = 'UPDATE_GIFTEE_LAST_NAME';
export const UPDATE_GIFTEE_EMAIL = 'UPDATE_GIFTEE_EMAIL';
export const UPDATE_GIFTEE_COUNTRY_CODE = 'UPDATE_GIFTEE_COUNTRY_CODE';
export const UPDATE_GIFTEE_PHONE_NUMBER = 'UPDATE_GIFTEE_PHONE_NUMBER';
export const SET_GIFTEE_PHONE = 'SET_GIFTEE_PHONE';
export const UPDATE_GIFTER_NAME = 'UPDATE_GIFTER_NAME';
export const UPDATE_GIFTER_PERSONAL_MESSAGE = 'UPDATE_GIFTER_PERSONAL_MESSAGE';

// ===== HARDWARE =====
export const SET_HORIZON_OPTIN = 'SET_HORIZON_OPTIN';
export const SET_HORIZON_SIZE = 'SET_HORIZON_SIZE';
export const SET_HORIZON_TINT = 'SET_HORIZON_TINT';
export const SET_HARDWARE_OPTIONS = 'SET_HARDWARE_OPTIONS';
export const SET_HARDWARE_SELECTED = 'SET_HARDWARE_SELECTED';

// ===== ORDER =====
export const UPDATE_OPERATING_COUNTRY = 'UPDATE_OPERATING_COUNTRY';
export const UPDATE_RESELLER = 'UPDATE_RESELLER';
export const APPLY_COUPON = 'APPLY_COUPON';
export const REMOVE_COUPON = 'REMOVE_COUPON';
export const TOGGLE_EMAIL_VERIFIED = 'TOGGLE_EMAIL_VERIFIED';
export const TOGGLE_IS_MANUAL_PAYMENT = 'TOGGLE_IS_MANUAL_PAYMENT';
export const UPDATE_PAYMENT_TYPE = 'UPDATE_PAYMENT_TYPE';
export const UPDATE_CARD_TYPE = 'UPDATE_CARD_TYPE';
export const UPDATE_CC = 'UPDATE_CC';
export const UPDATE_CC_CENSORED = 'UPDATE_CC_CENSORED';
export const UPDATE_CC_LAST_FOUR = 'UPDATE_CC_LAST_FOUR';
export const UPDATE_CC_FIRST_NAME = 'UPDATE_CC_FIRST_NAME';
export const UPDATE_CC_LAST_NAME = 'UPDATE_CC_LAST_NAME';
export const UPDATE_EXPIRATION = 'UPDATE_EXPIRATION';
export const UPDATE_EXPIRY_MONTH = 'UPDATE_EXPIRY_MONTH';
export const UPDATE_EXPIRY_YEAR = 'UPDATE_EXPIRY_YEAR';
export const UPDATE_CVV = 'UPDATE_CVV';
export const UPDATE_CVV_CENSORED = 'UPDATE_CVV_CENSORED';
export const UPDATE_ACH_FULL_NAME = 'UPDATE_ACH_FULL_NAME';
export const UPDATE_ACH_ROUTING_NUMBER = 'UPDATE_ACH_ROUTING_NUMBER';
export const UPDATE_ACH_ACCOUNT_NUMBER = 'UPDATE_ACH_ACCOUNT_NUMBER';
export const UPDATE_ACH_ACCOUNT_NUMBER_CENSORED = 'UPDATE_ACH_ACCOUNT_NUMBER_CENSORED';
export const UPDATE_ACH_ACCOUNT_NUMBER_LAST_FOUR = 'UPDATE_ACH_ACCOUNT_NUMBER_LAST_FOUR';
export const UPDATE_ACH_ACCOUNT_NUMBER_CONFIRMATION = 'UPDATE_ACH_ACCOUNT_NUMBER_CONFIRMATION';
export const UPDATE_ACH_ACCOUNT_TYPE = 'UPDATE_ACH_ACCOUNT_TYPE';
export const UPDATE_ORDER_SUMMARY = 'UPDATE_ORDER_SUMMARY';
export const UPDATE_ORDER_TOTAL = 'UPDATE_ORDER_TOTAL';
export const UPDATE_ORDER_TOTAL_CURRENCY = 'UPDATE_ORDER_TOTAL_CURRENCY';
export const UPDATE_RESELLER_ORDER_SUMMARY = 'UPDATE_RESELLER_ORDER_SUMMARY';
export const UPDATE_RESELLER_ORDER_TOTAL = 'UPDATE_RESELLER_ORDER_TOTAL';
export const UPDATE_RESELLER_ORDER_TOTAL_CURRENCY = 'UPDATE_RESELLER_ORDER_TOTAL_CURRENCY';

// ===== PLAN =====
export const TOGGLE_ACCOUNT_TYPE_INDIVIDUAL = 'TOGGLE_ACCOUNT_TYPE_INDIVIDUAL';
export const TOGGLE_ACCOUNT_TYPE_BUSINESS = 'TOGGLE_ACCOUNT_TYPE_BUSINESS';
export const SET_BUSINESS_ACCOUNT_ID = 'SET_BUSINESS_ACCOUNT_ID';
export const SET_BUSINESS_ACCOUNT_OPTIONS = 'SET_BUSINESS_ACCOUNT_OPTIONS';
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const SET_BUSINESS_ACCOUNTS = 'SET_BUSINESS_ACCOUNTS';
export const SET_PLAN_CODE = 'SET_PLAN_CODE';
export const SET_PLAN_INFO = 'SET_PLAN_INFO';
export const SET_PLAN_OPTIONS = 'SET_PLAN_OPTIONS';
export const SET_MINUTE_ADD_ON = 'SET_MINUTE_ADD_ON';
export const SET_MINUTE_ADD_ON_OPTIONS = 'SET_MINUTE_ADD_ON_OPTIONS';
export const SET_NFB_CONSENT = 'SET_NFB_CONSENT';

// ===== PROFILE =====
export const UPDATE_USER_ID = 'UPDATE_USER_ID';
export const UPDATE_ACCOUNT_CODE = 'UPDATE_ACCOUNT_CODE';
export const UPDATE_FIRST_NAME = 'UPDATE_FIRST_NAME';
export const UPDATE_LAST_NAME = 'UPDATE_LAST_NAME';
export const UPDATE_GENDER = 'UPDATE_GENDER';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const TOGGLE_NO_EMAIL = 'TOGGLE_NO_EMAIL';
export const UPDATE_COUNTRY_CODE = 'UPDATE_COUNTRY_CODE';
export const SET_COUNTRY_CODE_OPTIONS = 'SET_COUNTRY_CODE_OPTIONS';
export const UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER';
export const SET_PHONE = 'SET_PHONE';
export const TOGGLE_PHONE_VERIFIED = 'TOGGLE_PHONE_VERIFIED';
export const TOGGLE_NO_PHONE = 'TOGGLE_NO_PHONE';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_AGAIN = 'UPDATE_PASSWORD_AGAIN';
export const UPDATE_RECURLY_CODE = 'UPDATE_RECURLY_CODE';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_ENGLISH_SECOND_LANGUAGE = 'UPDATE_ENGLISH_SECOND_LANGUAGE';
export const UPDATE_IS_ASL_AGENT = 'UPDATE_IS_ASL_AGENT';

// ===== REFERRAL =====
export const SET_REFERRAL = 'SET_REFERRAL';
export const UNSET_REFERRAL = 'UNSET_REFERRAL';
export const UPDATE_REFERRAL_EMAIL = 'UPDATE_REFERRAL_EMAIL';

// ===== RESELLER =====
export const UPDATE_ACCOUNT_NAME = 'UPDATE_ACCOUNT_NAME';

// ===== SIGNUP =====
export const SET_STEP = 'SET_STEP';
export const INCREMENT_STEP = 'INCREMENT_STEP';
export const DECREMENT_STEP = 'DECREMENT_STEP';
export const TOGGLE_INTERNAL = 'TOGGLE_INTERNAL';
export const TOGGLE_GUEST = 'TOGGLE_GUEST';
export const TOGGLE_INAPP = 'TOGGLE_INAPP';
// analytics
export const UPDATE_LEAD_CODE = 'UPDATE_LEAD_CODE';
export const UPDATE_LEAD_VALUE_OPTION = 'UPDATE_LEAD_VALUE_OPTION';
export const UPDATE_LEAD_VALUE = 'UPDATE_LEAD_VALUE';

// ===== USER =====
export const TOGGLE_IS_LOGGED_IN = 'TOGGLE_IS_LOGGED_IN';
export const SET_ROLE = 'SET_ROLE';
export const UPDATE_SALES_ATTRIBUTION = 'UPDATE_SALES_ATTRIBUTION';
export const UPDATE_VERIFICATION = 'UPDATE_VERIFICATION';
export const UPDATE_PLATFORM_TOKEN = 'UPDATE_PLATFORM_TOKEN';

// ===== REMOTE CONFIG =====
export const REMOTE_CONFIG_OBSERVER = 'REMOTE_CONFIG_OBSERVER';
export const REMOTE_CONFIG_OBSERVERS_IDS = 'REMOTE_CONFIG_OBSERVERS_IDS';
