import * as types from '../mutation-types';
import API from '../../api';
import AiraError from '../../utils/AiraError';

// initial state
const state = {
  operatingCountry: 'US',
  reseller: '',
  couponCodes: [],
  isManualPayment: false,
  paymentType: null,
  cardType: null,
  cc: '',
  ccCensored: '',
  ccLastFour: '',
  ccFirstName: '',
  ccLastName: '',
  expiration: '',
  expiryMonth: '',
  expiryYear: '',
  cvv: '',
  cvvCensored: '',
  achFullName: '',
  achRoutingNumber: '',
  achAccountNumber: '',
  achAccountNumberCensored: '',
  achAccountNumberLastFour: '',
  achAccountNumberConfirmation: '',
  achAccountType: null,
  orderSummary: [],
  orderTotal: '',
  orderTotalCurrency: 'USD',
  resellerOrderSummary: [],
  resellerOrderTotal: '',
  resellerOrderTotalCurrency: 'USD',
};

// getters
const getters = {
  operatingCountry: (_state) => _state.operatingCountry,
  reseller: (_state) => _state.reseller,
  couponCodes: (_state) => _state.couponCodes,
  isManualPayment: (_state) => _state.isManualPayment,
  paymentType: (_state) => _state.paymentType,
  cardType: (_state) => _state.cardType,
  cc: (_state) => _state.cc,
  ccCensored: (_state) => _state.ccCensored,
  ccLastFour: (_state) => _state.ccLastFour,
  ccFirstName: (_state) => _state.ccFirstName,
  ccLastName: (_state) => _state.ccLastName,
  expiration: (_state) => _state.expiration,
  expiryMonth: (_state) => _state.expiryMonth,
  expiryYear: (_state) => _state.expiryYear,
  cvv: (_state) => _state.cvv,
  cvvCensored: (_state) => _state.cvvCensored,
  achFullName: (_state) => _state.achFullName,
  achRoutingNumber: (_state) => _state.achRoutingNumber,
  achAccountNumber: (_state) => _state.achAccountNumber,
  achAccountNumberCensored: (_state) => _state.achAccountNumberCensored,
  achAccountNumberLastFour: (_state) => _state.achAccountNumberLastFour,
  achAccountNumberConfirmation: (_state) => _state.achAccountNumberConfirmation,
  achAccountType: (_state) => _state.achAccountType,
  orderSummary: (_state) => _state.orderSummary,
  orderTotal: (_state) => _state.orderTotal,
  orderTotalCurrency: (_state) => _state.orderTotalCurrency,
  resellerOrderSummary: (_state) => _state.resellerOrderSummary,
  resellerOrderTotal: (_state) => _state.resellerOrderTotal,
  resellerOrderTotalCurrency: (_state) => _state.resellerOrderTotalCurrency,
};

/* eslint-disable no-shadow */
const actions = {
  async getOrderPreview({ rootGetters, commit, dispatch }) {
    try {
      const payload = await dispatch('getPayload');
      let response;
      if (!rootGetters.isInApp && rootGetters.isGuest) payload.paymentInfo = { type: 'MANUAL' };

      if (rootGetters.isGuest && rootGetters.isInternal) {
        response = await API.getInternalGuestUpgradeOrderPreview(rootGetters.userId, payload);
      } else if (rootGetters.isGuest) {
        response = await API.getGuestUpgradeOrderPreview(payload);
      } else if (rootGetters.isInternal) {
        response = await API.getInternalOrderPreview(payload);
      } else {
        response = await API.getOrderPreview(payload);
      }
      commit(types.UPDATE_ORDER_SUMMARY, response.summary);
      commit(types.UPDATE_ORDER_TOTAL, response.todayTotalInCents);
      commit(types.UPDATE_ORDER_TOTAL_CURRENCY, response.currency);
      return true;
    } catch (error) {
      console.error(error);
      const err = new AiraError(error);
      commit(types.PUSH_ERRORS, [err.errorMessage]);
      return false;
    }
  },
  async getExplorerUpgradeOrderPreview({ rootGetters, commit }) {
    try {
      // make payload
      const payload = {
        orderlines: [
          {
            productCode: rootGetters.planCode,
            productType: 'primary',
            quantity: 1,
          },
        ],
      };

      const response = await API.getExplorerUpgradeOrderPreview(payload);
      if (response) {
        commit(types.UPDATE_ORDER_SUMMARY, response.summary);
        commit(types.UPDATE_ORDER_TOTAL, response.todayTotalInCents);
        commit(types.UPDATE_ORDER_TOTAL_CURRENCY, response.currency);
      }
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  async getAddOnOrderPreview({ rootGetters, commit }) {
    try {
      // make payload
      const payload = {
        addOnCode: rootGetters.minuteAddOn.code,
      };

      const response = await API.getAddOnOrderPreview(payload);
      if (response) {
        commit(types.UPDATE_ORDER_SUMMARY, response.summary);
        commit(types.UPDATE_ORDER_TOTAL, response.todayTotalInCents);
        commit(types.UPDATE_ORDER_TOTAL_CURRENCY, response.currency);
      }
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  async doExplorerUpgrade({ rootGetters }) {
    try {
      // make payload
      const payload = {
        orderlines: [
          {
            productCode: rootGetters.planCode,
            productType: 'primary',
            quantity: 1,
          },
        ],
      };
      return await API.doExplorerUpgrade(payload);
    } catch (error) {
      throw (new AiraError(error));
    }
  },
  async doAddOnPurchase({ rootGetters }) {
    try {
      // make payload
      const payload = {
        addOnCode: rootGetters.minuteAddOn.code,
      };
      return await API.doAddOnPurchase(payload);
    } catch (error) {
      throw (new AiraError(error));
    }
  },
  async doSignup({ rootGetters, commit, dispatch }) {
    try {
      const payload = await dispatch('getPayload');
      let response;
      if (rootGetters.isGuest && rootGetters.isInternal) {
        response = await API.doInternalGuestUpgrade(rootGetters.userId, payload);
      } else if (rootGetters.isGuest) {
        response = await API.doGuestUpgrade(payload);
      } else if (rootGetters.isInternal) {
        response = await API.doInternalSignup(payload);
      } else {
        response = await API.doSignup(payload);
      }
      return response.userId;
    } catch (error) {
      console.error(error);
      const err = new AiraError(error);
      commit(types.PUSH_ERRORS, [err.errorMessage]);
      return false;
    }
  },
  async doGuestSignup({ rootGetters }, phoneNumber) {
    try {
      const payload = {
        phoneNumber,
        salesAttribution: rootGetters.salesAttribution,
      };
      await API.doGuestSignup(payload);
    } catch (error) {
      throw (new AiraError(error));
    }
  },
  async doGuestSignupBasic({ commit }, payload) {
    try {
      return await API.doGuestSignupBasic(payload);
    } catch (error) {
      console.error(error);
      const err = new AiraError(error);
      commit(types.PUSH_ERRORS, [err.errorMessage]);
      return false;
    }
  },
  async validateCoupon({ rootGetters }, coupon) {
    try {
      // console.log('API~validateCoupon: ', coupon, data);
      const response = await API.validateCoupon(coupon);
      return (response.coupon.appliesToAllPlans || response.coupon.planCodes.includes(rootGetters.planCode) || await response.coupon.planCodes.filter((code) => (code.name === rootGetters.planCode)));
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [types.UPDATE_OPERATING_COUNTRY](_state, payload) {
    _state.operatingCountry = payload;
  },
  [types.APPLY_COUPON](_state, payload) {
    _state.couponCodes.push(payload);
  },
  [types.REMOVE_COUPON](_state, payload) {
    _state.couponCodes.splice(_state.couponCodes.indexOf(payload), 1);
  },
  [types.UPDATE_RESELLER](_state, payload) {
    _state.reseller = payload;
  },
  [types.TOGGLE_IS_MANUAL_PAYMENT](_state) {
    _state.isManualPayment = !_state.isManualPayment;
  },
  [types.UPDATE_PAYMENT_TYPE](_state, payload) {
    _state.paymentType = payload;
  },
  [types.UPDATE_CARD_TYPE](_state, payload) {
    _state.cardType = payload;
  },
  [types.UPDATE_CC](_state, payload) {
    _state.cc = payload;
  },
  [types.UPDATE_CC_CENSORED](_state, payload) {
    _state.ccCensored = payload;
  },
  [types.UPDATE_CC_LAST_FOUR](_state, payload) {
    _state.ccLastFour = payload;
  },
  [types.UPDATE_CC_FIRST_NAME](_state, payload) {
    _state.ccFirstName = payload;
  },
  [types.UPDATE_CC_LAST_NAME](_state, payload) {
    _state.ccLastName = payload;
  },
  [types.UPDATE_EXPIRATION](_state, payload) {
    _state.expiration = payload;
    // set expiryMonth and expiryYear when field is complete
    if (_state.expiration.length === 7) {
      [_state.expiryMonth, _state.expiryYear] = payload.split('/');
    }
  },
  [types.UPDATE_EXPIRY_MONTH](_state, payload) {
    _state.expiryMonth = payload;
  },
  [types.UPDATE_EXPIRY_YEAR](_state, payload) {
    _state.expiryYear = payload;
  },
  [types.UPDATE_CVV](_state, payload) {
    _state.cvv = payload;
  },
  [types.UPDATE_CVV_CENSORED](_state, payload) {
    _state.cvvCensored = payload;
  },
  [types.UPDATE_ACH_FULL_NAME](_state, payload) {
    _state.achFullName = payload;
  },
  [types.UPDATE_ACH_ROUTING_NUMBER](_state, payload) {
    _state.achRoutingNumber = payload;
  },
  [types.UPDATE_ACH_ACCOUNT_NUMBER](_state, payload) {
    _state.achAccountNumber = payload;
  },
  [types.UPDATE_ACH_ACCOUNT_NUMBER_CENSORED](_state, payload) {
    _state.achAccountNumberCensored = payload;
  },
  [types.UPDATE_ACH_ACCOUNT_NUMBER_LAST_FOUR](_state, payload) {
    _state.achAccountNumberLastFour = payload;
  },
  [types.UPDATE_ACH_ACCOUNT_NUMBER_CONFIRMATION](_state, payload) {
    _state.achAccountNumberConfirmation = payload;
  },
  [types.UPDATE_ACH_ACCOUNT_TYPE](_state, payload) {
    _state.achAccountType = payload;
  },
  [types.UPDATE_ORDER_SUMMARY](_state, payload) {
    _state.orderSummary = payload;
  },
  [types.UPDATE_ORDER_TOTAL](_state, payload) {
    _state.orderTotal = payload;
  },
  [types.UPDATE_ORDER_TOTAL_CURRENCY](_state, payload) {
    _state.orderTotalCurrency = payload;
  },
  [types.UPDATE_RESELLER_ORDER_SUMMARY](_state, payload) {
    _state.resellerOrderSummary = payload;
  },
  [types.UPDATE_RESELLER_ORDER_TOTAL](_state, payload) {
    _state.resellerOrderTotal = payload;
  },
  [types.UPDATE_RESELLER_ORDER_TOTAL_CURRENCY](_state, payload) {
    _state.resellerOrderTotalCurrency = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
