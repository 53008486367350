import * as types from '../mutation-types';

// initial state
const state = {
  // signup flags & variables
  step: 1,
  isInternal: false,
  isGuest: false,
  isInApp: false,

  // analytics
  leadCode: '',
  leadValueOption: '',
  leadValue: '',
};

// getters
const getters = {
  // signup flags & variables
  step: (_state) => _state.step,
  isInternal: (_state) => _state.isInternal,
  isGuest: (_state) => _state.isGuest,
  isInApp: (_state) => _state.isInApp,

  // analytics
  leadCode: (_state) => _state.leadCode,
  leadValueOption: (_state) => _state.leadValueOption,
  leadValue: (_state) => _state.leadValue,
};

/* eslint-disable no-shadow */
const actions = {
  getPayload({ getters, rootGetters }) {
    // ORDERLINES
    const orderlines = [];

    if (rootGetters.accountTypeIndividual) {
      orderlines.push({
        productCode: rootGetters.planCode,
        productType: 'primary',
        quantity: 1,
      });
    }

    // HARDWARE & HORIZON
    if (rootGetters.hardwareSelected !== 'none') {
      orderlines.push({
        productCode: rootGetters.hardwareSelectedInfo.value,
        productType: rootGetters.hardwareSelectedInfo.productType,
        quantity: 1,
      });
    }

    const horizon = {
      optIn: rootGetters.horizonOptIn,
      size: rootGetters.horizonSize,
      tint: rootGetters.horizonTint,
    };

    // ADDITIONAL ACCOUNTS
    const additionalAccounts = rootGetters.accountTypeBusiness ? [{
      accountId: rootGetters.businessAccountId,
      userType: 'business',
      consumeOnSite: false,
    }] : null;

    // ACCOUNT & PROFILE
    const countryCodeAlpha = rootGetters.countryCodeOptions.find((option) => option.value === rootGetters.countryCode);
    const account = {
      firstname: rootGetters.firstName,
      lastname: rootGetters.lastName,
      email: rootGetters.email,
      phone: rootGetters.noPhone ? null : rootGetters.phone,
      countryCode: ((rootGetters.isInApp && rootGetters.isGuest) ? null : countryCodeAlpha.country),
      gender: 'not-disclosed',
      password: rootGetters.password || null,
      accountType: rootGetters.accountType,
    };

    // BILLING ADDRESS
    const billingAddress = {
      address1: rootGetters.address1,
      city: rootGetters.city,
      state: rootGetters.state,
      zip: rootGetters.zip,
      country: rootGetters.country,
    };

    const billingToken = {
      tokenId: rootGetters.recurlyCode,
    };

    // SHIPPING ADDRESS
    const shippingAddress = (rootGetters.hardwareSelected !== 'none') ? {
      address1: rootGetters.shippingAddress1,
      address2: rootGetters.shippingAddress2,
      city: rootGetters.shippingCity,
      state: rootGetters.shippingState,
      zip: rootGetters.shippingZip,
      country: rootGetters.shippingCountry,
    } : null;

    // PAYMENT INFO
    const paymentInfo = (rootGetters.isManualPayment || (rootGetters.accountTypeBusiness && !rootGetters.accountTypeIndividual)) ? {
      type: 'MANUAL',
    } : {
      type: rootGetters.paymentType || 'CC',
    };

    // EXTRAS
    const extras = {
      authProvider: 'AIRA',
      couponCodes: rootGetters.couponCodes.length ? rootGetters.couponCodes : null,
    };

    // RESELLER CODE
    let resellerCode = '';
    if (rootGetters.leadCode === 'WoM' && rootGetters.leadValue === 'Vision Australia') {
      resellerCode = 'Vision Australia';
    }

    const payload = {
      verificationCode: rootGetters.verification,
      operatingCountry: ((rootGetters.isInApp && rootGetters.isGuest) ? null : rootGetters.operatingCountry),
      salesAttribution: rootGetters.salesAttribution || null,
      leadCode: getters.leadCode || null,
      leadValue: getters.leadValue || null,
      referralCode: rootGetters.referral || null,
      resellerCode: resellerCode || null,
      orderlines,
      horizon,
      additionalAccounts,
      account,
      billingAddress,
      billingToken,
      shippingAddress,
      paymentInfo,
      extras,
      originInternal: rootGetters.isInternal || null,
    };
    return payload;
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  // signup flags & variables
  [types.SET_STEP](_state, payload) {
    _state.step = payload;
  },
  [types.INCREMENT_STEP](_state) {
    _state.step += 1;
  },
  [types.DECREMENT_STEP](_state) {
    _state.step -= 1;
  },
  [types.TOGGLE_INTERNAL](_state, payload) {
    _state.isInternal = payload;
  },
  [types.TOGGLE_GUEST](_state, payload) {
    _state.isGuest = payload;
  },
  [types.TOGGLE_INAPP](_state, payload) {
    _state.isInApp = payload;
  },

  // analytics
  [types.UPDATE_LEAD_CODE](_state, payload) {
    _state.leadCode = payload;
    _state.leadValueOption = '';
    _state.leadValue = '';
  },
  [types.UPDATE_LEAD_VALUE_OPTION](_state, payload) {
    _state.leadValueOption = payload;
    if (payload === 'Other') {
      _state.leadValue = '';
    } else {
      _state.leadValue = payload;
    }
  },
  [types.UPDATE_LEAD_VALUE](_state, payload) {
    _state.leadValue = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
