import * as types from '../mutation-types';

// initial state
const state = {
  debug: false,
  errors: [],
  warning: [],
  notice: [],
  needRefresh: false,
};

// getters
const getters = {
  debug: (_state) => _state.debug,
  errors: (_state) => _state.errors,
  warning: (_state) => _state.warning,
  notice: (_state) => _state.notice,
  needRefresh: (_state) => _state.needRefresh,
};

/* eslint-disable no-shadow */
const actions = {

};

/* eslint-disable no-param-reassign */
const mutations = {
  [types.TOGGLE_DEBUG](_state) {
    _state.debug = !_state.debug;
  },
  [types.PUSH_ERRORS](_state, payload) {
    _state.errors = _state.errors.concat(payload);
  },
  [types.CLEAR_ERRORS](_state) {
    _state.errors = [];
  },
  [types.PUSH_WARNING](_state, payload) {
    _state.warning = _state.warning.concat(payload);
  },
  [types.PUSH_NOTICE](_state, payload) {
    _state.notice = _state.notice.concat(payload);
  },
  [types.CLEAR_NOTICE](_state) {
    _state.notice = [];
  },
  [types.SET_NEED_REFRESH](_state) {
    _state.needRefresh = true;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
