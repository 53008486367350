import * as types from '../mutation-types';
import form from '../../data/form.json';

// initial state
const state = {
  countryOptions: form.countryOptions,
  operatingCountryOptions: form.operatingCountryOptions,
  shippingStateOptions: form.stateOptions,
  stateOptions: form.stateOptions,
  sameBillingShipping: true,
  sameShippingBilling: true,
  // billing info
  address1: '',
  city: '',
  state: '',
  country: 'US',
  zip: '',
  // shipping info
  shippingAddress1: '',
  shippingAddress2: '',
  shippingCountry: 'US',
  shippingCity: '',
  shippingState: '',
  shippingZip: '',
};

// getters
const getters = {
  countryOptions: (_state) => _state.countryOptions,
  operatingCountryOptions: (_state) => _state.operatingCountryOptions,
  shippingStateOptions: (_state) => _state.shippingStateOptions,
  stateOptions: (_state) => _state.stateOptions,
  sameBillingShipping: (_state) => _state.sameBillingShipping,
  sameShippingBilling: (_state) => _state.sameShippingBilling,
  // billing info
  address1: (_state) => _state.address1,
  city: (_state) => _state.city,
  state: (_state) => _state.state,
  country: (_state) => _state.country,
  zip: (_state) => _state.zip,
  // shipping info
  shippingAddress1: (_state) => _state.shippingAddress1,
  shippingAddress2: (_state) => _state.shippingAddress2,
  shippingCity: (_state) => _state.shippingCity,
  shippingState: (_state) => _state.shippingState,
  shippingCountry: (_state) => _state.shippingCountry,
  shippingZip: (_state) => _state.shippingZip,
};

/* eslint-disable no-shadow */
const actions = {

};

/* eslint-disable no-param-reassign */
const mutations = {
  [types.TOGGLE_SAME_BILLING_SHIPPING](_state, payload) {
    _state.sameBillingShipping = payload;
    if (payload) {
      _state.address1 = _state.shippingAddress1;
      _state.city = _state.shippingCity;
      _state.state = _state.shippingState;
      _state.country = _state.shippingCountry;
      _state.zip = _state.shippingZip;
    } else {
      _state.address1 = '';
      _state.city = '';
      _state.state = '';
      _state.zip = '';
    }
  },
  [types.TOGGLE_SAME_SHIPPING_BILLING](_state, payload) {
    _state.sameShippingBilling = payload;
    if (payload) {
      _state.shippingAddress1 = _state.address1;
      _state.shippingCity = _state.city;
      _state.shippingState = _state.state;
      _state.shippingCountry = _state.country;
      _state.shippingZip = _state.zip;
    } else {
      _state.shippingAddress1 = '';
      _state.shippingCity = '';
      _state.shippingState = '';
      _state.shippingZip = '';
    }
  },
  // billing info
  [types.UPDATE_ADDRESS1](_state, payload) {
    _state.address1 = payload;
    if (_state.sameShippingBilling) _state.shippingAddress1 = payload;
  },
  [types.UPDATE_CITY](_state, payload) {
    _state.city = payload;
    if (_state.sameShippingBilling) _state.shippingCity = payload;
  },
  [types.UPDATE_STATE](_state, payload) {
    _state.state = payload;
    if (_state.sameShippingBilling) _state.shippingState = payload;
  },
  [types.UPDATE_COUNTRY](_state, payload) {
    _state.country = payload;
    _state.state = '';
    if (_state.sameShippingBilling) {
      _state.shippingCountry = payload;
      _state.shippingState = '';
    }
    switch (payload) {
      case 'US':
        _state.stateOptions = form.stateOptions;
        break;
      case 'CA':
        _state.stateOptions = form.canadaProvinceOptions;
        break;
      case 'AU':
        _state.stateOptions = form.australiaStateOptions;
        break;
      case 'NZ':
        _state.stateOptions = form.newZealandProvinceOptions;
        break;
      // link below as reference for GB provinces
      // http://www.factiva.com/CP_Developer/ProductHelp/FDK/FDK20/registration/shared_code_tables/state_province_table/united_kingdom_state_province_table.htm
      case 'GB':
        _state.stateOptions = form.unitedKingdomProvinceOptions;
        break;
      default:
        _state.stateOptions = [];
        break;
    }
  },
  [types.UPDATE_ZIP](_state, payload) {
    _state.zip = payload;
    if (_state.sameShippingBilling) _state.shippingZip = payload;
  },
  // shipping info
  [types.UPDATE_SHIPPING_ADDRESS1](_state, payload) {
    _state.shippingAddress1 = payload;
    if (_state.sameBillingShipping) _state.address1 = payload;
  },
  [types.UPDATE_SHIPPING_ADDRESS2](_state, payload) {
    _state.shippingAddress2 = payload;
  },
  [types.UPDATE_SHIPPING_COUNTRY](_state, payload) {
    _state.shippingCountry = payload;
    _state.shippingState = '';
    if (_state.sameBillingShipping) {
      _state.country = payload;
      _state.state = '';
    }
    switch (payload) {
      case 'US':
        _state.shippingStateOptions = form.stateOptions;
        break;
      case 'CA':
        _state.shippingStateOptions = form.canadaProvinceOptions;
        break;
      case 'AU':
        _state.shippingStateOptions = form.australiaStateOptions;
        break;
      case 'NZ':
        _state.shippingStateOptions = form.newZealandProvinceOptions;
        break;
      // link below as reference for GB provinces
      // http://www.factiva.com/CP_Developer/ProductHelp/FDK/FDK20/registration/shared_code_tables/state_province_table/united_kingdom_state_province_table.htm
      case 'GB':
        _state.shippingStateOptions = form.unitedKingdomProvinceOptions;
        break;
      default:
        _state.shippingStateOptions = [];
        break;
    }
  },
  [types.UPDATE_SHIPPING_CITY](_state, payload) {
    _state.shippingCity = payload;
    if (_state.sameBillingShipping) _state.city = payload;
  },
  [types.UPDATE_SHIPPING_STATE](_state, payload) {
    _state.shippingState = payload;
    if (_state.sameBillingShipping) _state.state = payload;
  },
  [types.UPDATE_SHIPPING_ZIP](_state, payload) {
    _state.shippingZip = payload;
    if (_state.sameBillingShipping) _state.zip = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
