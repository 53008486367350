import Vue from 'vue';
import Router from 'vue-router';
import Branch from 'branch-sdk';
import store from './store';
import API from './api/index';
import form from './data/form.json';

Vue.use(Router);

// External
const Signup = () => import('./components/Signup.vue');

// Internal
const Internal = () => import('./components/Internal.vue');
const InternalHome = () => import('./components/Internal/InternalHome.vue');
const InternalSignup = () => import('./components/Internal/InternalSignup.vue');
const InternalGuestSignup = () => import('./components/Internal/InternalGuestSignup.vue');
const InternalResellerSignup = () => import('./components/Internal/InternalResellerSignup.vue');
const Report = () => import('./components/Internal/Report.vue');
const ChatReport = () => import('./components/Internal/ChatReport.vue');
const AgentActivity = () => import('./components/Internal/AgentActivity.vue');

// Mobile
const Mobile = () => import('./components/Mobile.vue');
const GuestUpgrade = () => import('./components/GuestUpgrade/GuestUpgrade.vue');
const ExplorerUpgrade = () => import('./components/ExplorerUpgrade/ExplorerUpgrade.vue');

// User
const User = () => import('./components/User.vue');
const UserProfile = () => import('./components/User/UserProfile.vue');
const UserProfileEdit = () => import('./components/User/UserProfileEdit.vue');
const UserProfileVerify = () => import('./components/User/UserProfileVerify.vue');
const UserProfileConfirm = () => import('./components/User/UserProfileConfirm.vue');

// Reseller
const Reseller = () => import('./components/Reseller.vue');
const ResellerHome = () => import('./components/Reseller/ResellerHome.vue');
const ResellerPurchaseOrder = () => import('./components/Reseller/ResellerPurchaseOrder.vue');
const ResellerProfile = () => import('./components/Reseller/ResellerProfile.vue');

// Gift
const GiftCard = () => import('./components/GiftCard.vue');

// Access Partner
const AccessPartner = () => import('./components/AccessPartner/AccessPartner.vue');
const AccessPartnerSuccess = () => import('./components/AccessPartner/AccessPartnerSuccess.vue');

// Password Reset
// const Password = () => import('./components/Password.vue');
const PasswordReset = () => import('./components/Password/PasswordReset.vue');
const PasswordResetConfirmation = () => import('./components/Password/PasswordResetConfirmation.vue');

// Error
const error403 = () => import('./components/Error/403.vue');
const error404 = () => import('./components/Error/404.vue');
const error500 = () => import('./components/Error/500.vue');

// Misc
const Sorry = () => import('./components/Error/Sorry.vue');

// Uber
const UberConfirmation = () => import('./components/UberConfirmation.vue');

// Join Account
const JoinAccount = () => import('./components/JoinAccount.vue');

// Agent Dash google SSO
const AgentDashSSOConfirmation = () => import('./components/AgentDashSSOConfirmation.vue');

export default new Router({
  mode: 'history',
  routes: [
    /* eslint-disable */
    // ===== EXTERNAL =====
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      meta: { title: 'Sign Up | Aira', layout: 'external' },
      beforeEnter (to, from, next) {
        // make sure plan query parameter is used
        if (!to.query || !to.query.plan) {
          window.location = 'https://aira.io/pricing';
        }

        // set step to 0
        store.commit('SET_STEP', 0);

        // plan
        store.commit('SET_PLAN_CODE', to.query.plan);
        if (to.query.operatingCountry) {
          const operatingCountry = to.query.operatingCountry.toUpperCase();
          if (form.operatingCountryOptions.find(option => option.value === operatingCountry)) {
            store.commit('UPDATE_OPERATING_COUNTRY', operatingCountry);
            store.commit('UPDATE_COUNTRY', operatingCountry);
            store.commit('UPDATE_SHIPPING_COUNTRY', operatingCountry);
            switch (operatingCountry) {
              case 'GB':
                store.commit('UPDATE_COUNTRY_CODE', '+44');
                break;
              case 'AU':
                store.commit('UPDATE_COUNTRY_CODE', '+61');
                break;
              case 'NZ':
                store.commit('UPDATE_COUNTRY_CODE', '+64');
                break;
              default:
                store.commit('UPDATE_COUNTRY_CODE', '+1');
            }
          }
        }

        store.dispatch('getPlan').then((response) => {
          if (!response) {
            window.location.href = to.query.referral ? `https://aira.io/pricing/?referral=${to.query.referral}` : 'https://aira.io/pricing';
          } else {
            document.title = to.meta.title;
            next();
          }
        });
      }
    },

    // ===== INTERNAL SIGNUPS =====
    {
      path: '/internal',
      component: Internal,
      meta: { title: 'Internal Aira' },
      children: [
        { path: '', name: 'internal', component: InternalHome, meta: { title: 'Internal Home | Aira', layout: 'basic' } },
        { path: 'signup', name: 'internalSignup', component: InternalSignup, meta: { title: 'Internal Sign Up | Aira', layout: 'basic' }, beforeEnter: (to, from, next) => {
          if ($cookies.get('isASL') === 'true') {
            next('/'); // Redirect to home if isASL is true
          } else {
            next();
          }
        } },
        { path: 'guest-signup', name: 'internalGuestSignup', component: InternalGuestSignup, meta: { title: 'Internal Guest Sign Up | Aira', layout: 'basic' }, beforeEnter: (to, from, next) => {
          if ($cookies.get('isASL') === 'true') {
            next('/'); // Redirect to home if isASL is true
          } else {
            next();
          }
        }},
        { path: 'reseller-signup', name: 'internalResellerSignup', component: InternalResellerSignup, meta: { title: 'Internal Reseller Sign Up | Aira', layout: 'basic' }, beforeEnter: (to, from, next) => {
          if ($cookies.get('isASL') === 'true') {
            next('/'); // Redirect to home if isASL is true
          } else {
            next();
          }
        } },
        { path: 'report', name: 'report', component: Report, meta: { title: 'Report | Aira', layout: 'basic' } },
        { path: 'chatReport', name: 'chatReport', component: ChatReport, meta: { title: 'Chat Report | Aira', layout: 'basic' },  beforeEnter: (to, from, next) => {
          if ($cookies.get('isASL') === 'true') {
            next('/'); // Redirect to home if isASL is true
          } else {
            next();
          }
        } },
        { path: 'agent-activity', name: 'agentActivity', component: AgentActivity, meta: { title: 'Agent Activity | Aira', layout: 'basic' } },
        { path: '*', redirect: '/' },
      ],
      beforeEnter (to, from, next) {
        if (to.query.emp) store.commit('TOGGLE_IS_EMPLOYEE', true);
        store.commit('TOGGLE_SAME_BILLING_SHIPPING', false);
        store.commit('TOGGLE_INTERNAL', true);

        if (to.query.phoneNumber) {
          store.commit('SET_PHONE', to.query.phoneNumber.replace(' ', '+'));
        }

        // set default title
        document.title = to.meta.title;
       
        next();
      }
    },

    // ===== MOBILE =====
    { path: '/guest-upgrade', redirect: '/mobile/guest-upgrade' },
    {
      path: '/mobile',
      name: 'mobile',
      component: Mobile,
      meta: { title: 'Aira', layout: 'blank' },
      children: [
        { path: '/mobile/guest-upgrade', name: 'mobileGuestUpgrade', component: GuestUpgrade, meta: { title: '', layout: 'blank' } },
        { path: '/mobile/explorer-upgrade', name: 'mobileExplorerUpgrade', component: ExplorerUpgrade, meta: { title: '', layout: 'blank' } },
        { path: '/mobile/profile', name: 'mobileProfile', component: UserProfile, meta: { title: 'Profile', layout: 'blank' } },
        { path: '/mobile/profile/edit/', name: 'mobileEdit', component: UserProfileEdit, meta: { title: 'Edit Profile', layout: 'blank' } },
        { path: '/mobile/profile/edit/:type', name: 'mobileEditType', component: UserProfileEdit, meta: { title: 'Edit Profile', layout: 'blank' } },
        { path: '/mobile/profile/verify/:type', name: 'mobileVerify', component: UserProfileVerify, meta: { title: 'Verify', layout: 'blank' } },
        { path: '/mobile/profile/confirm', name: 'mobileConfirm', component: UserProfileConfirm, meta: { title: 'Confirm', layout: 'blank' } },
      ],
      beforeEnter (to, from, next) {
        document.title = to.meta.title;
        store.commit('TOGGLE_INAPP', true);
        if (to.name === 'mobileConfirm') {
          next();
        } else if (to.name === 'mobileVerify' && (to.query.type === 'EMAIL_RESET' || to.query.type === 'PHONE_RESET')) {
          next();
        } else {
          if (!$cookies.get('ssoToken')) {
            store.commit('PUSH_ERRORS', ['SSO token missing.']);
            next('/500');
          } else {
            API.setSsoToken($cookies.get('ssoToken'));
            store.dispatch('validateUserToken').then((response) => {
              if (!response) {
                store.commit('PUSH_ERRORS', ['SSO token invalid.']);
                next('/500');
              } else {
                if (to.name === 'mobileGuestUpgrade') {
                  store.commit('TOGGLE_GUEST', true);
                  store.commit('TOGGLE_SAME_SHIPPING_BILLING', false);

                  if ($cookies.get('phoneNumber')) {
                    store.commit('SET_PHONE', $cookies.get('phoneNumber'));
                    store.commit('TOGGLE_NO_PHONE', false);
                  } else {
                    store.commit('TOGGLE_NO_PHONE', true);
                  }

                  store.dispatch('getOrderOptions').then((response) => {
                    if (!response) {
                      next('/sorry');
                    } else {
                      document.title = to.meta.title;
                      next();
                    }
                  });
                } else if (to.name === 'mobileExplorerUpgrade') {
                  store.dispatch('getOrderOptions').then((response) => {
                    store.dispatch('getUserBilling');
                    document.title = to.meta.title;
                    next();
                  });
                } else {
                  if ((to.name === 'mobileEdit' || to.name === 'mobileVerify') && !(to.params.type === 'phone' || to.params.type === 'email' || to.params.type === 'name' || to.params.type === 'billing')) {
                    store.dispatch('getUserProfile');
                    store.dispatch('getUserBilling');
                    next('/400');
                  } else {
                    store.dispatch('getUserProfile');
                    store.dispatch('getUserBilling');
                    next();
                  }
                }
              }
            });
          }
        }
      },
    },

    // ===== USER =====
    {
      path: '/user',
      name: 'user',
      component: User,
      meta: { title: 'Aira User', layout: 'external' },
      children: [
        { path: '/user/profile/confirm', name: 'userConfirm', component: UserProfileConfirm, meta: { title: 'Confirm | Aira', layout: 'external' },
          async beforeEnter (to, from, next) {
            if (!to.query._branch_match_id) {
              store.commit('PUSH_ERRORS', ['Link is invalid.']);
              next('/500');
            } else {
              Branch.init(process.env.VUE_APP_BRANCH_KEY, { branch_match_id: to.query._branch_match_id }, (error, data) => {
                if (error) {
                  console.error(error);
                  store.commit('PUSH_ERRORS', ['Link is invalid.']);
                  next('/500');
                } else if (!data.data_parsed.key || !data.data_parsed.value || !data.data_parsed.type) {
                  store.commit('PUSH_ERRORS', ['Link is invalid.']);
                  next('/500');
                } else {
                  store.dispatch('verificationConfirm', { type: data.data_parsed.type, key: data.data_parsed.key }).then((response) => {
                    document.title = to.meta.title;
                    next({ path: '/mobile/profile/confirm', query: { type: data.data_parsed.type } });
                  }).catch((error) => {
                    console.error('verificationConfirm error', error);
                    if (data.data_parsed.type === 'PHONE_RESET') {
                      store.commit('SET_PHONE', data.data_parsed.value);
                      next({ path: '/mobile/profile/verify/phone-expired', query: { type: data.data_parsed.type, key: data.data_parsed.key } });
                    } else if (data.data_parsed.type === 'EMAIL_RESET') {
                      store.commit('UPDATE_EMAIL', data.data_parsed.value);
                      next({ path: '/mobile/profile/verify/email-expired', query: { type: data.data_parsed.type, key: data.data_parsed.key } });
                    } else {
                      store.commit('PUSH_ERRORS', ['Link is invalid.']);
                      next('/500');
                    }
                  });
                }
              });
            }
          }
        },
      ]
    },

    // ===== RESELLER =====
    {
      path: '/reseller',
      component: Reseller,
      meta: { title: 'Aira Reseller Portal', layout: 'external' },
      children: [
        { path: '', name: 'reseller', component: ResellerHome, meta: { title: 'Reseller Dashboard | Aira', layout: 'external' } },
        { path: 'purchase-order', name: 'resellerPurchaseOrder', component: ResellerPurchaseOrder, meta: { title: 'Purchase Order | Aira', layout: 'external' } },
        { path: 'profile/edit/:type', name: 'resellerProfile', component: ResellerProfile, meta: { title: 'Edit Profile | Aira', layout: 'external' } },
        { path: '*', redirect: '/' },
      ],
    },

    // ===== GIFT =====
    {
      path: '/gift-card',
      component: GiftCard,
      meta: { title: 'Aira Gift Card', layout: 'external' },
    },

    // ===== ACCESS PARTNER =====
    { path: '/access-partner', name: 'AccessPartner', component: AccessPartner, meta: { title: 'Access Partner', layout: 'external' } },
    { path: '/access-partner/success', name: 'AccessPartnerSuccess', component: AccessPartnerSuccess, meta: { title: 'Access Partner', layout: 'external' } },

    // ===== PASSWORD =====
    { path: '/password-reset', name: 'passwordReset', component: PasswordReset, meta: { title: 'Password Reset | Aira', layout: 'external'} },
    { path: '/password-reset-confirmation', name: 'passwordResetConfirmation', component: PasswordResetConfirmation, meta: { title: 'Password Reset | Aira', layout: 'external'}},

    // ===== JOIN ACCOUNT =====
    { path: '/account/join', name: 'joinAccount', component: JoinAccount, meta: { title: 'Join Account | Aira', layout: 'external'}},

    // ===== ERROR =====
    { path: '/403', name: '403', component: error403, meta: { title: 'Forbidden', layout: 'default' } },
    { path: '/404', name: '404', component: error404, meta: { title: '404 Not Found', layout: 'default' } },
    { path: '/500', name: '500', component: error500, meta: { title: 'Uh oh..', layout: 'default' } },

    // ===== MISC =====
    {
      path: '/sorry',
      name: 'sorry',
      component: Sorry,
      meta: { title: '', layout: 'blank' },
      beforeEnter (to, from, next) {
        document.title = to.meta.title;
        next();
      }
    },
    {
      path: '/uber-surge-pricing-confirmed',
      meta: {
        title: 'Uber Surge Pricing Confirmed',
        layout: 'external',
      },
      component: UberConfirmation,
    },
    {
      path: '/dash-login-confirmed',
      meta: {
        title: 'Agent Dash Login Success',
        layout: 'external',
      },
      component: AgentDashSSOConfirmation,
    },
    { path: '*', redirect: '/404' },
    {
      path: '/',
      beforeEnter(to, from, next){
        window.location = 'https://aira.io';
        next();
      }
    },
  ],
});
