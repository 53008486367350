<template>
  <div
    id="external-layout"
    class="container-fluid"
  >
    <page-header />
    <div class="layout-container">
      <slot />
    </div>
    <page-footer />
  </div>
</template>

<script>
// External Layout - Header & Footer
// multi-layout router system -> https://itnext.io/anyway-heres-how-to-create-a-multiple-layout-system-with-vue-and-vue-router-b379baa91a05

import PageHeader from './LayoutComponents/PageHeader.vue';
import PageFooter from './LayoutComponents/PageFooter.vue';

export default {
  name: 'External',
  components: {
    PageHeader,
    PageFooter,
  },
};
</script>

<style lang="scss">
@import '../styles/styles.scss';
#external-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  .layout-container {
    flex-grow: 1;
    background-color: $aira-bg-color-container;
  }
}
</style>
