<template>
  <div
    id="default-layout"
    class="container-fluid"
  >
    <div class="layout-container d-flex align-items-center">
      <slot />
    </div>
  </div>
</template>

<script>
// Default Layout - a nice loading bg (?)
// multi-layout router system -> https://itnext.io/anyway-heres-how-to-create-a-multiple-layout-system-with-vue-and-vue-router-b379baa91a05
export default {
  name: 'Default',
};
</script>

<style lang="scss">
@import '../styles/styles.scss';
#default-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #63b1bc;
  @media (max-width: $aira-min-width) {
    background-color: #171717;
  }
  .layout-container {
    background: url('../assets/404_pattern.svg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    flex-grow: 1;
    @media (max-width: $aira-min-width) {
      opacity: .5;
      h2, p {
        color: white;
      }
    }
  }
}
</style>
