/* eslint-disable no-underscore-dangle */

class AiraError {
  constructor(err) {
    this._errorCode = '';
    this._status = null;
    this._errorMessage = '';

    if (err.response) {
      this._status = err.response.status;
    }

    if (err.response && err.response.data) {
      const { data } = err.response;
      if (data.response) {
        this._errorCode = data.response.errorCode;
        this._errorMessage = data.response.errorMessage;
      } else if (data.length && data[0].message) {
        this._errorMessage = data[0].message;
      }
    }

    if (!err.response) {
      this._errorCode = '408';
      this._status = 'FAILURE';
      this._errorMessage = 'Request Timed Out. Please try again.';
    }
  }

  get errorCode() {
    return this._errorCode;
  }

  get status() {
    return this._status;
  }

  get errorMessage() {
    return this._errorMessage;
  }
}

export default AiraError;
