import API from '../../api';

// initial state
const state = {};

// getters
const getters = {};

const actions = {
  // eslint-disable-next-line
    async joinAccountVerifyCode({ }, payload) {
    console.debug('Verify code payload: ', JSON.stringify(payload));
    return API.joinAccountVerifyCode(payload);
  },
  // eslint-disable-next-line
    async joinAccountCreateUser({ rootGetters }, inviteCode) {
    let countryCodeAlpha;
    try {
      countryCodeAlpha = await rootGetters.countryCodeOptions.find((option) => option.value === rootGetters.countryCode);
      console.debug('Country code: ', JSON.stringify(countryCodeAlpha));
      // countryCodeAlpha if US {"value":"+1", "text":"+1 (US)", "country":"US"}
    } catch (e) {
      console.error(e);
    }
    const payload = {
      firstName: rootGetters.firstName,
      lastName: rootGetters.lastName,
      password: rootGetters.password,
      code: inviteCode,
      phoneNumber: rootGetters.phoneNumber,
      countryCode: countryCodeAlpha.country,
      email: rootGetters.email,
    };
    console.debug('Join account create user payload: ', JSON.stringify(payload));
    return API.joinAccountCreateUser(payload);
  },
};

/* eslint-disable no-param-reassign */
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
