<template>
  <div
    id="blank-layout"
    class="container-fluid"
  >
    <div class="layout-container">
      <slot />
    </div>
  </div>
</template>

<script>
// Blank Layout - empty white
// multi-layout router system -> https://itnext.io/anyway-heres-how-to-create-a-multiple-layout-system-with-vue-and-vue-router-b379baa91a05
export default {
  name: 'Blank',
};
</script>

<style lang="scss">
@import '../styles/styles.scss';
@import '../styles/theme/_mobile.scss';

#blank-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  .layout-container {
    flex-grow: 1;
    background-color: $body-bg;
    color: $body-color;
  }
}
</style>
