// import * as types from '../mutation-types';
import API from '../../api';

// initial state
const state = {

};

// getters
const getters = {

};

/* eslint-disable no-shadow */
const actions = {
  // deprecated
  async updateUserPropertySingle({ rootGetters }, data) {
    API.updateUserPropertySingle(rootGetters.userId, data.propertyKey, data.payload);
  },
  /**
   * Update Property Value
   * @param {*} params { propertykey, data }
   * data - { property: value, property: value, ... }
   */
  async updatePropertyValue({ rootGetters }, params) {
    console.log('yeehaw', params);
    return API.updatePropertyValue(rootGetters.userId, params.propertyKey, params.data);
  },
};

/* eslint-disable no-param-reassign */
const mutations = {

};

export default {
  state,
  getters,
  actions,
  mutations,
};
