import * as types from '../mutation-types';
import API from '../../api';
import { compare, alphabet } from '../../utils';

// initial state
const state = {
  accountTypeIndividual: true,
  accountTypeBusiness: false,
  accountType: 'individual',
  businessAccountId: '',
  businessAccountOptions: null,
  subscriptions: null,
  businessAccounts: null,
  planCode: '',
  plan: {},
  planOptions: [],
  minuteAddOn: null,
  minuteAddOnOptions: [],
  nfbConsent: false,
};

// getters
const getters = {
  accountTypeIndividual: (_state) => _state.accountTypeIndividual,
  accountTypeBusiness: (_state) => _state.accountTypeBusiness,
  accountType: (_state) => _state.accountType,
  businessAccountId: (_state) => _state.businessAccountId,
  businessAccountOptions: (_state) => _state.businessAccountOptions,
  subscriptions: (_state) => _state.subscriptions,
  businessAccounts: (_state) => _state.businessAccounts,
  planCode: (_state) => _state.planCode,
  plan: (_state) => _state.plan,
  planOptions: (_state) => _state.planOptions,
  minuteAddOn: (_state) => _state.minuteAddOn,
  minuteAddOnOptions: (_state) => _state.minuteAddOnOptions,
  nfbConsent: (_state) => _state.nfbConsent,
};

/* eslint-disable no-shadow */
const actions = {
  // eslint-disable-next-line
  async getPlan({ getters, rootGetters, commit, dispatch }) {
    try {
      const response = await API.getPlan(getters.planCode, rootGetters.operatingCountry);
      const hardwareOptions = await dispatch('handleHardware', response);
      const plan = response;
      plan.hardwareOptions = hardwareOptions;
      commit(types.SET_PLAN_INFO, plan);
      commit(types.SET_HARDWARE_OPTIONS, hardwareOptions);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async getOrderOptions({ commit, dispatch }) {
    try {
      const response = await API.getOrderOptions();

      if (response.primaryPlans.length) {
        commit(types.SET_PLAN_OPTIONS, await dispatch('handlePlans', response.primaryPlans));
      }

      if (response.minuteAddOnAdjustments.length) {
        commit(types.SET_MINUTE_ADD_ON_OPTIONS, response.minuteAddOnAdjustments);
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async getInternalSignupPlans({ rootGetters, commit, dispatch }) {
    try {
      const response = await API.getInternalPlans(rootGetters.operatingCountry, true);
      if (!response.plans.length) return false;

      commit(types.SET_PLAN_CODE, '');
      commit(types.SET_HARDWARE_OPTIONS, []);

      const planOptions = await dispatch('handlePlans', response.plans);
      commit(types.SET_PLAN_OPTIONS, planOptions);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async getResellerPlans({ commit, dispatch }) {
    try {
      const response = await API.getResellerPlans();

      if (response.plans.length) {
        commit(types.SET_PLAN_OPTIONS, await dispatch('handlePlans', response.plans));
      }
    } catch (error) {
      console.error(error);
    }
  },
  async handlePlans({ dispatch }, plans) {
    try {
      const planOptions = plans.sort(compare);
      const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: planOptions[0].currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      });
      /* eslint-disable */
      for (let option of planOptions) {
        option.hardwareOptions = await dispatch('handleHardware', option);
        option.info = option.description.replace(/\n/g, '').split('*');
        option.value = option.planCode;
        option.displayPrice = (option.price > 0) ? currencyFormatter.format(option.price / 100) : 'FREE';

        let minutes = option.minutes;
        if (minutes >= 44640) {
          minutes = 'Unlimited minutes —';
        } else if (minutes <= 0) {
          minutes = '';
        } else {
          minutes = `${minutes} minutes —`
        }
        option.text = `${option.planName} — ${option.displayPrice}${(option.price > 0) ? '/mo' : ''} — ${minutes} ${option.hardwareOptions.length ? 'Optional Glasses' : 'No Glasses'}`;
      }
      return planOptions;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  setPlan({ getters, commit }) {
    if (getters.planOptions.length) {
      if (getters.planCode) {
        const plan = getters.planOptions.find(option => option.planCode === getters.planCode);
        commit(types.SET_HARDWARE_OPTIONS, plan.hardwareOptions);
        commit(types.SET_PLAN_INFO, plan);
      } else {
        commit(types.SET_HARDWARE_OPTIONS, []);
      }
    }
  },
  async getBusinessAccounts({ getters, commit }) {
    try {
      if (getters.businessAccountOptions) return false;
      const response = await API.getBusinessAccounts();
      // eslint-disable-next-line
      if (response) {
        const businessAccountOptions = [];
        response.forEach((option) => {
          let disabled = [];
          if (getters.businessAccounts) disabled = getters.businessAccounts.filter(account => (account.accountId === option.accountId));
          businessAccountOptions.push({
            text: `${option.name} (${option.accountId})`,
            value: option.accountId,
            disabled: disabled.length || false,
          });
        });
        businessAccountOptions.sort(alphabet);
        commit(types.SET_BUSINESS_ACCOUNT_OPTIONS, businessAccountOptions);
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [types.TOGGLE_ACCOUNT_TYPE_INDIVIDUAL](_state, payload) {
    _state.accountTypeIndividual = payload;
  },
  [types.TOGGLE_ACCOUNT_TYPE_BUSINESS](_state, payload) {
    _state.accountTypeBusiness = payload;
  },
  [types.SET_BUSINESS_ACCOUNT_ID](_state, payload) {
    _state.businessAccountId = payload;
  },
  [types.SET_BUSINESS_ACCOUNT_OPTIONS](_state, payload) {
    _state.businessAccountOptions = payload;
  },
  [types.SET_SUBSCRIPTIONS](_state, payload) {
    _state.subscriptions = payload;
  },
  [types.SET_BUSINESS_ACCOUNTS](_state, payload) {
    _state.businessAccounts = payload;
  },
  [types.SET_PLAN_CODE](_state, payload) {
    _state.planCode = payload;
  },
  [types.SET_PLAN_INFO](_state, payload) {
    _state.plan = payload;
  },
  [types.SET_PLAN_OPTIONS](_state, payload) {
    _state.planOptions = payload;
    _state.planCode = '';
    _state.plan = {};
  },
  [types.SET_MINUTE_ADD_ON](_state, payload) {
    _state.minuteAddOn = payload;
  },
  [types.SET_MINUTE_ADD_ON_OPTIONS](_state, payload) {
    _state.minuteAddOnOptions = payload;
  },
  [types.SET_NFB_CONSENT](_state, payload) {
    _state.nfbConsent = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
