import API from '../../api';

// initial state
const state = {};

// getters
const getters = {};

const actions = {
  // eslint-disable-next-line
  async passwordReset({ }, payload) {
    const response = await API.passwordReset(payload);
    return response.response;
  },
  // eslint-disable-next-line
  async passwordResetVerifyCode({ }, payload) {
    return API.passwordResetVerifyCode(payload);
  },
  // eslint-disable-next-line
  async updatePassword({ }, payload) {
    return API.updatePassword(payload);
  },
};

/* eslint-disable no-param-reassign */
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
