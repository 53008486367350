<template>
  <div
    id="basic-layout"
    class="container-fluid"
  >
    <div class="layout-container">
      <img
        id="logo"
        :aria-hidden="true"
        src="../assets/Logo@2x.png"
      >
      <slot />
    </div>
  </div>
</template>

<script>
// Basic Layout - Logo Icon only
// multi-layout router system -> https://itnext.io/anyway-heres-how-to-create-a-multiple-layout-system-with-vue-and-vue-router-b379baa91a05

export default {
  name: 'Basic',
};
</script>

<style lang="scss">
@import '../styles/styles.scss';
#basic-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  .layout-container {
    #logo {
      padding-top: 30px;
      margin-left: auto;
      margin-right: auto;
    }
    flex-grow: 1;
    background-color: $aira-bg-color-container;
  }
}
</style>
