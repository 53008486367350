import * as types from '../mutation-types';
import API from '../../api';

// initial state
const state = {
  referral: '', // is the verified field
  referralEmail: '', // field holder that needs verification
};

// getters
const getters = {
  referral: (_state) => _state.referral,
  referralEmail: (_state) => _state.referralEmail,
};

/* eslint-disable no-shadow */
const actions = {
  async verifyReferralEmail({ commit }, email) {
    try {
      const response = await API.isEmailInUse(email);
      if (response.result) {
        await commit(types.UPDATE_LEAD_CODE, 'Referral');
        await commit(types.UPDATE_LEAD_VALUE_OPTION, email);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async getReferralInfo({ commit }, referralCode) {
    try {
      await API.getReferralInfo(referralCode);
      commit(types.SET_REFERRAL, referralCode);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [types.SET_REFERRAL](_state, payload) {
    _state.referral = payload;
  },
  [types.UNSET_REFERRAL](_state) {
    _state.referral = '';
    _state.referralEmail = '';
  },
  [types.UPDATE_REFERRAL_EMAIL](_state, payload) {
    _state.referralEmail = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
