import VueCookies from 'vue-cookies';
import * as types from '../mutation-types';
import API from '../../api';
import form from '../../data/form.json';
import AiraError from '../../utils/AiraError';

const GENDER = {
  GENDER_FLUID: { value: 'gender-fluid', text: 'Gender Fluid' },
  NOT_DISCLOSED: { value: 'not-disclosed', text: 'Not Disclosed' },
  MALE: { value: 'male', text: 'Male' },
  FEMALE: { value: 'female', text: 'Female' },
};

// initial state
const state = {
  userId: null,
  accountCode: null, // recurly account code
  lastName: '',
  firstName: '',
  gender: GENDER.NOT_DISCLOSED.value,
  email: '',
  noEmail: true,
  countryCode: '+1',
  countryCodeOptions: form.countryCodeOptions,
  phoneNumber: '',
  phone: '',
  phoneVerified: false,
  noPhone: false,
  password: '',
  passwordAgain: '',
  recurlyCode: '',
  language: 'English',
  englishSecondLanguage: false,
  isASLAgent: false,
};

// getters
const getters = {
  userId: (_state) => _state.userId,
  isASLAgent: (_state) => _state.isASLAgent,
  accountCode: (_state) => _state.accountCode,
  firstName: (_state) => _state.firstName,
  lastName: (_state) => _state.lastName,
  gender: (_state) => _state.gender,
  email: (_state) => _state.email,
  noEmail: (_state) => _state.noEmail,
  countryCode: (_state) => _state.countryCode,
  countryCodeOptions: (_state) => _state.countryCodeOptions,
  phoneNumber: (_state) => _state.phoneNumber,
  phone: (_state) => _state.phone,
  phoneVerified: (_state) => _state.phoneVerified,
  noPhone: (_state) => _state.noPhone,
  password: (_state) => _state.password,
  passwordAgain: (_state) => _state.passwordAgain,
  recurlyCode: (_state) => _state.recurlyCode,
  language: (_state) => _state.language,
  formattedLanguage: (_state) => {
    switch (_state.language) {
      case 'French':
        return 'Français';
      case 'Spanish':
        return 'Español';
      default:
        return 'English';
    }
  },
  englishSecondLanguage: (_state) => _state.englishSecondLanguage,
  formattedEnglishSecondLanguage: (_state) => {
    switch (_state.language) {
      case 'French':
        return _state.englishSecondLanguage ? 'Oui' : 'Non';
      case 'Spanish':
        return _state.englishSecondLanguage ? 'Sí' : 'No';
      default:
        return _state.englishSecondLanguage ? 'Yes' : 'No';
    }
  },
};

/* eslint-disable no-shadow */
const actions = {
  async signupVerify({ getters, commit }) {
    try {
      const countryCodeAlpha = await getters.countryCodeOptions.find((option) => option.value === getters.countryCode);
      const response = await API.signupVerify({ phoneNumber: getters.phone, countryCode: countryCodeAlpha.country });
      if (response.login) commit(types.SET_PHONE, response.login);
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  async signupVerifyConfirm({ getters }, auth) {
    try {
      return await API.signupVerifyConfirm({ phoneNumber: getters.phone, authCode: auth });
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  async userVerify({ getters, commit }) {
    try {
      const countryCodeAlpha = await getters.countryCodeOptions.find((option) => option.value === getters.countryCode);
      const response = await API.userVerify({
        phoneNumber: getters.phone,
        countryCode: countryCodeAlpha.country,
        eventType: 'RESET',
      });
      if (response.login) commit(types.SET_PHONE, response.login);
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  async userVerifyConfirm({ getters }, auth) {
    try {
      return await API.userVerifyConfirm({
        phoneNumber: getters.phone,
        authCode: auth,
        eventType: 'RESET',
      });
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  async verifyPhone({ getters, commit, dispatch }) {
    try {
      dispatch('resetUserStatus');

      const countryCodeAlpha = await getters.countryCodeOptions.find((option) => option.value === getters.countryCode);
      const response = await API.verifyPhone({ phoneNumber: getters.phone, countryCode: countryCodeAlpha.country });
      if (response.userId) {
        commit(types.UPDATE_USER_ID, response.userId);
        commit(types.TOGGLE_GUEST, true);
        if (response.email) {
          commit(types.UPDATE_EMAIL, response.email);
          commit(types.TOGGLE_NO_EMAIL, false);
        } else {
          commit(types.TOGGLE_NO_EMAIL, true);
        }
        if (response.subscriptionDetails.primarySubscription) {
          commit(types.SET_SUBSCRIPTIONS, response.subscriptionDetails.primarySubscription);
          commit(types.TOGGLE_ACCOUNT_TYPE_INDIVIDUAL, false);
          commit(types.TOGGLE_ACCOUNT_TYPE_BUSINESS, true);
        }
        if (response.businessAccounts.length) commit(types.SET_BUSINESS_ACCOUNTS, response.businessAccounts);
        return 'guest';
      } else {
        commit(types.TOGGLE_NO_EMAIL, true);
        return 'new';
      }
    } catch (error) {
      console.error(error);
      return 'invalid';
    }
  },
  async resellerVerifyPhone({ getters, commit, dispatch }) {
    try {
      dispatch('resetUserStatus');

      const countryCodeAlpha = await getters.countryCodeOptions.find((option) => option.value === getters.countryCode);
      const response = await API.resellerVerifyPhone({ phoneNumber: getters.phone, countryCode: countryCodeAlpha.country });
      if (response.userId && response.subscriptionDetails.primarySubscription) {
        commit(types.UPDATE_USER_ID, response.userId);
        return 'existing';
      } else if (response.userId) {
        commit(types.UPDATE_USER_ID, response.userId);
        commit(types.TOGGLE_GUEST, true);
        if (response.email) {
          commit(types.UPDATE_EMAIL, response.email);
          commit(types.TOGGLE_NO_EMAIL, false);
        } else {
          commit(types.TOGGLE_NO_EMAIL, true);
        }
        return 'guest';
      } else {
        commit(types.TOGGLE_NO_EMAIL, true);
        return 'new';
      }
    } catch (error) {
      console.error(error);
      return 'invalid';
    }
  },
  // eslint-disable-next-line
  async verifyPhoneData({ getters }, data) {
    // data is { countryCode, phoneNumber}
    try {
      const countryCodeAlpha = await getters.countryCodeOptions.find((option) => option.value === data.countryCode);
      const response = await API.verifyPhone({ phoneNumber: data.phone, countryCode: countryCodeAlpha.country });
      if (response.userId) {
        return 'user';
      } else {
        return 'new';
      }
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  resetUserStatus({ commit }) {
    commit(types.TOGGLE_GUEST, false);
    commit(types.SET_SUBSCRIPTIONS, null);
    commit(types.SET_BUSINESS_ACCOUNTS, null);
    commit(types.UPDATE_FIRST_NAME, '');
    commit(types.UPDATE_LAST_NAME, '');
    commit(types.UPDATE_USER_ID, '');
    commit(types.UPDATE_EMAIL, '');
    commit(types.TOGGLE_ACCOUNT_TYPE_INDIVIDUAL, true);
    commit(types.TOGGLE_ACCOUNT_TYPE_BUSINESS, false);
  },
  async getUserProfile({ getters, commit }, userIdParam = null) {
    try {
      const userId = userIdParam || getters.userId;
      const response = await API.getUserProfile(userId);
      if (response) {
        commit(types.TOGGLE_PHONE_VERIFIED, response.phoneVerified);
        const isASL = response.properties.routingGroup.some((item) => item.value === 'ASL');
        commit(types.UPDATE_IS_ASL_AGENT, isASL);
        VueCookies.set('isASL', isASL);
        if (response.accountCode) commit(types.UPDATE_ACCOUNT_CODE, response.accountCode);

        if (response.resetPhoneNumber) {
          commit(types.SET_PHONE, response.resetPhoneNumber);
        } else if (response.phoneNumber) {
          commit(types.SET_PHONE, response.phoneNumber);
          commit(types.TOGGLE_NO_PHONE, false);
        } else {
          commit(types.TOGGLE_NO_PHONE, true);
        }

        if (response.resetEmail) {
          commit(types.UPDATE_EMAIL, response.resetEmail);
        } else if (response.email) {
          commit(types.UPDATE_EMAIL, response.email);
          commit(types.TOGGLE_NO_EMAIL, false);
        } else {
          commit(types.TOGGLE_NO_EMAIL, true);
        }

        if (response.firstName) commit(types.UPDATE_FIRST_NAME, response.firstName);
        if (response.lastName) commit(types.UPDATE_LAST_NAME, response.lastName);

        if (response.language && response.language.includes('French')) {
          commit(types.UPDATE_LANGUAGE, 'French');
          commit(types.UPDATE_ENGLISH_SECOND_LANGUAGE, response.language.includes('English'));
        } else if (response.language && response.language.includes('Spanish')) {
          commit(types.UPDATE_LANGUAGE, 'Spanish');
          commit(types.UPDATE_ENGLISH_SECOND_LANGUAGE, response.language.includes('English'));
        } else {
          // English is the default Agent language
          commit(types.UPDATE_LANGUAGE, 'English');
          commit(types.UPDATE_ENGLISH_SECOND_LANGUAGE, false);
        }
      }
      // console.log(response);
    } catch (error) {
      console.error(error);
    }
  },
  async updateName({ getters, dispatch }) {
    try {
      const payload = {
        firstName: [{ value: getters.firstName }],
        lastName: [{ value: getters.lastName }],
      };

      const firstNameResponse = await dispatch('updatePropertyValue', { propertyKey: 'firstName', data: payload.firstName });
      const lastNameResponse = await dispatch('updatePropertyValue', { propertyKey: 'lastName', data: payload.lastName });

      console.trace('updateName response:', firstNameResponse, lastNameResponse);
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  // eslint-disable-next-line
  async updateEmail({ }, email) {
    try {
      return API.updateEmail(email);
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  // eslint-disable-next-line
  async updatePhone({ }, payload) {
    try {
      return API.updatePhone(payload);
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  async reverifyPhone({ getters }) {
    try {
      return API.reverifyPhone(getters.phone);
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  // eslint-disable-next-line
  async verificationConfirm({}, payload) {
    try {
      return API.verificationConfirm(payload);
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  // eslint-disable-next-line
  async verificationResend({}, payload) {
    try {
      return API.verificationResend(payload);
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  async getUserBilling({ commit }) {
    try {
      const response = await API.getUserBilling();
      if (response) {
        if (!response.cardType) {
          commit(types.UPDATE_CARD_TYPE, null);
        } else {
          commit(types.UPDATE_CARD_TYPE, response.cardType);
          if (response.firstName) commit(types.UPDATE_CC_FIRST_NAME, response.firstName);
          if (response.lastName) commit(types.UPDATE_CC_LAST_NAME, response.lastName);
          if (response.number) commit(types.UPDATE_CC_CENSORED, response.number);
          if (response.lastFour) commit(types.UPDATE_CC_LAST_FOUR, response.lastFour);
          if (response.verificationValue) commit(types.UPDATE_CVV_CENSORED, response.verificationValue);
          if (response.month) commit(types.UPDATE_EXPIRY_MONTH, response.month);
          if (response.year) commit(types.UPDATE_EXPIRY_YEAR, response.year);
        }
        if (response.accountType) {
          commit(types.UPDATE_ACH_ACCOUNT_TYPE, response.accountType);
          if (response.number) commit(types.UPDATE_ACH_ACCOUNT_NUMBER_CENSORED, response.number);
          if (response.lastFour) commit(types.UPDATE_ACH_ACCOUNT_NUMBER_LAST_FOUR, response.lastFour);
          if (response.nameOnAccount) commit(types.UPDATE_ACH_FULL_NAME, response.nameOnAccount);
          if (response.routingNumber) commit(types.UPDATE_ACH_ROUTING_NUMBER, response.routingNumber);
        }
        if (response.address1) commit(types.UPDATE_ADDRESS1, response.address1);
        if (response.city) commit(types.UPDATE_CITY, response.city);
        if (response.country) commit(types.UPDATE_COUNTRY, response.country);
        if (response.state) commit(types.UPDATE_STATE, response.state);
        if (response.zip) commit(types.UPDATE_ZIP, response.zip);
      }
    } catch (error) {
      console.error(error);
    }
  },
  // eslint-disable-next-line
  async putUserBilling({}, tokenId) {
    try {
      const response = await API.putUserBilling({ tokenId });
      console.trace('putUserBilling', response);
      return true;
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [types.UPDATE_USER_ID](_state, payload) {
    _state.userId = payload;
  },
  [types.UPDATE_ACCOUNT_CODE](_state, payload) {
    _state.accountCode = payload;
  },
  [types.UPDATE_FIRST_NAME](_state, payload) {
    _state.firstName = payload;
  },
  [types.UPDATE_LAST_NAME](_state, payload) {
    _state.lastName = payload;
  },
  [types.UPDATE_GENDER](_state, payload) {
    _state.gender = payload;
  },
  [types.UPDATE_EMAIL](_state, payload) {
    _state.email = payload;
  },
  [types.TOGGLE_NO_EMAIL](_state, payload) {
    _state.noEmail = payload;
  },
  [types.UPDATE_COUNTRY_CODE](_state, payload) {
    _state.countryCode = payload;
    _state.phone = `${payload}${_state.phoneNumber}`;
  },
  [types.SET_COUNTRY_CODE_OPTIONS](_state, payload) {
    _state.countryCodeOptions = payload;
  },
  [types.UPDATE_PHONE_NUMBER](_state, payload) {
    _state.phoneNumber = payload;
    _state.phone = `${_state.countryCode}${payload}`;
  },
  [types.SET_PHONE](_state, payload) {
    // PHONE = COUNTRYCODE + PHONENUMBER
    _state.phone = payload;
  },
  [types.TOGGLE_PHONE_VERIFIED](_state, payload) {
    _state.phoneVerified = payload;
  },
  [types.TOGGLE_NO_PHONE](_state, payload) {
    _state.noPhone = payload;
  },
  [types.UPDATE_PASSWORD](_state, payload) {
    _state.password = payload;
  },
  [types.UPDATE_PASSWORD_AGAIN](_state, payload) {
    _state.passwordAgain = payload;
  },
  [types.UPDATE_RECURLY_CODE](_state, payload) {
    _state.recurlyCode = payload;
  },
  [types.UPDATE_IS_ASL_AGENT](_state, payload) {
    _state.isASLAgent = payload;
  },
  [types.UPDATE_LANGUAGE](_state, payload) {
    switch (payload) {
      case 'Français':
        _state.language = 'French';
        break;
      case 'Español':
        _state.language = 'Spanish';
        break;
      default:
        _state.language = payload;
    }
  },
  [types.UPDATE_ENGLISH_SECOND_LANGUAGE](_state, payload) {
    _state.englishSecondLanguage = payload === true || payload === 'Oui' || payload === 'Sí' || payload === 'Yes';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
