<template>
  <div id="page-footer">
    <footer class="d-flex footer">
      <div class="footer-left mr-auto">
        <div class="text-left">
          @ Copyright 2019-2021
        </div>
      </div>
      <div class="footer-right">
        <div class="footer-item text-right">
          <a
            href="https://aira.io/terms-of-service"
            target="_blank"
          >
            Terms of Service
          </a>
        </div>
        <div class="footer-item text-right">
          <a
            href="https://aira.io/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables/_colors.scss';

#page-footer {
  height: 60px;
  width: 100%;
  position: relative;
  display: block;
  bottom: 0;
  background-color: $aira-bg-color-container;
  .footer {
    height: inherit;
    margin: 0 8vw 0 8vw;
    border-top: 2px solid $aira-color-divider;
  }
  .footer-left, .footer-right {
    line-height: 60px;
    display: inline-block;
  }
  .footer-item {
    padding-left: 20px;
    display: inline-block;
  }
}
</style>
