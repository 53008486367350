<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { getValue, fetchAndActivate } from '@firebase/remote-config';
import { remoteConfig } from './utils/firebase';
import * as types from './store/mutation-types';

const defaultLayout = 'default';
export default {
  name: 'App',
  computed: {
    layout() {
      return `${this.$route.meta.layout || defaultLayout}-layout`;
    },
  },
  created() {
    this.fetchRemoteConfig();
  },
  methods: {
    async fetchRemoteConfig() {
      const defaultObserverConfig = { allowedAgents: [] };
      if (remoteConfig == null) {
        console.error('Remote config is not defined');
        this.$store.commit(types.REMOTE_CONFIG_OBSERVER, defaultObserverConfig);
        return;
      }
      // Uncomment the following lines to refresh the remote config on every app load when developing
      // Remote config fetch interval is set to 12 hours by default
      // remoteConfig.settings = {
      //   minimumFetchIntervalMillis: 0,
      // };
      remoteConfig.defaultConfig = {
        DASH_OBSERVER: defaultObserverConfig,
      };

      try {
        await fetchAndActivate(remoteConfig);
        const observerValue = getValue(remoteConfig, 'DASH_OBSERVER');
        const rcObserverEnabled = JSON.parse(observerValue.asString());
        this.$store.commit('REMOTE_CONFIG_OBSERVER', rcObserverEnabled);
        const observersIdsValue = getValue(remoteConfig, 'SSP_OBSERVERS_IDS');
        const observersIds = JSON.parse(observersIdsValue.asString());
        this.$store.commit('REMOTE_CONFIG_OBSERVERS_IDS', observersIds);
      } catch (error) {
        console.error('Error fetching remote config: ', error);
      }
    },
  },
};
</script>
