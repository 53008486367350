import * as types from '../mutation-types';
import API from '../../api';
import AiraError from '../../utils/AiraError';

// initial state
const state = {
  accountName: '',
};

// getters
const getters = {
  accountName: (_state) => _state.accountName,
};

/* eslint-disable no-shadow */
const actions = {
  getResellerPayload({ rootGetters }) {
    const orderlines = [];
    orderlines.push({
      productCode: rootGetters.planCode,
      productType: 'primary',
      quantity: 1,
    });
    if (rootGetters.hardwareSelected !== 'none') {
      orderlines.push({
        productCode: rootGetters.hardwareSelectedInfo.value,
        productType: rootGetters.hardwareSelectedInfo.productType,
        quantity: 1,
      });
    }
    const horizon = {
      optIn: rootGetters.horizonOptIn,
      size: rootGetters.horizonSize,
      tint: rootGetters.horizonTint,
    };

    // ACCOUNT & PROFILE
    const countryCodeAlpha = rootGetters.countryCodeOptions.find((option) => option.value === rootGetters.countryCode);
    const customer = {
      userId: rootGetters.userId || null,
      firstName: rootGetters.firstName,
      lastName: rootGetters.lastName,
      email: rootGetters.email,
      phoneNumber: (rootGetters.userId ? null : rootGetters.phoneNumber),
      countryCode: (rootGetters.userId ? null : countryCodeAlpha.country),
    };

    // SHIPPING ADDRESS
    let shippingAddress;
    if (rootGetters.sameShippingBilling) {
      shippingAddress = (rootGetters.hardwareSelected !== 'none') ? {
        address1: rootGetters.address1,
        city: rootGetters.city,
        state: rootGetters.state,
        zip: rootGetters.zip,
        country: rootGetters.country,
      } : null;
    } else {
      shippingAddress = (rootGetters.hardwareSelected !== 'none') ? {
        address1: rootGetters.shippingAddress1,
        address2: rootGetters.shippingAddress2,
        city: rootGetters.shippingCity,
        state: rootGetters.shippingState,
        zip: rootGetters.shippingZip,
        country: rootGetters.shippingCountry,
      } : null;
    }

    const payload = {
      customer,
      horizon,
      shippingAddress,
      orderlines,
    };

    return payload;
  },
  async postResellerAccount({ rootGetters }) {
    try {
      const payload = {
        salesAttribution: rootGetters.salesAttribution,
        firstName: rootGetters.firstName,
        lastName: rootGetters.lastName,
        accountName: rootGetters.accountName,
        email: rootGetters.email,
      };
      return await API.postResellerAccount(payload);
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  async postVerifyEmail({ rootGetters }) {
    try {
      const payload = {
        email: rootGetters.email,
      };

      return await API.postVerifyEmail(payload);
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  async postVerifyEmailConfirm({ rootGetters }, auth) {
    try {
      const payload = {
        email: rootGetters.email,
        authCode: auth,
      };

      const response = await API.postVerifyEmailConfirm(payload);
      return response;
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  async getResellerOrderPreview({ commit, dispatch }) {
    try {
      const payload = await dispatch('getResellerPayload');
      const response = await API.getResellerOrderPreview(payload);

      // commit(types.UPDATE_ORDER_SUMMARY, response.customer.summary);
      // commit(types.UPDATE_ORDER_TOTAL, response.customer.todayTotalInCents);
      // commit(types.UPDATE_ORDER_TOTAL_CURRENCY, response.customer.currency);
      commit(types.UPDATE_RESELLER_ORDER_SUMMARY, response.reseller.summary);
      commit(types.UPDATE_RESELLER_ORDER_TOTAL, response.reseller.todayTotalInCents);
      commit(types.UPDATE_RESELLER_ORDER_TOTAL_CURRENCY, response.reseller.currency);
      return response;
    } catch (error) {
      console.error(error);
      const err = new AiraError(error);
      commit(types.PUSH_ERRORS, [err.errorMessage]);
      return false;
    }
  },
  async postResellerOrder({ commit, dispatch }) {
    try {
      const payload = await dispatch('getResellerPayload');
      const response = await API.postResellerOrder(payload);
      return response;
    } catch (error) {
      const err = new AiraError(error);
      commit(types.PUSH_ERRORS, [err.errorMessage]);
      return false;
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [types.UPDATE_ACCOUNT_NAME](_state, payload) {
    _state.accountName = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
