import { getAuth, signInWithCustomToken, signOut } from '@firebase/auth';
import VueCookies from 'vue-cookies';
import * as types from '../mutation-types';
import API from '../../api';
import AiraError from '../../utils/AiraError';
import { app } from '../../utils/firebase';

// roles
export const ROLES = {
  ADMIN: 'ADMIN',
  TESTER: 'TESTER',
  'AIRA AGENT': 'AIRA AGENT',
  'LV USER': 'LV USER',
  RESELLER: 'RESELLER',
  DEFAULT: 'DEFAULT',
  DI_MANAGER: 'DI_MANAGER',
};

// initial state
const state = {
  isLoggedIn: false,
  role: [],
  salesAttribution: '',
  verification: '',
  platformToken: '',
};

// getters
const getters = {
  isLoggedIn: (_state) => _state.isLoggedIn,
  role: (_state) => _state.role,
  salesAttribution: (_state) => _state.salesAttribution,
  verification: (_state) => _state.verification,
  platformToken: (_state) => _state.platformToken,
};

async function getRoles(response) {
  const roles = (response.rolePermissions || []).map((rp) => rp.roleName);
  if (roles.includes('ADMIN')) {
    // HACK: A DialedIn Manager (DI_MANAGER) is an ADMIN with the DialedIn routing group.
    const routingGroups = (await API.getUserProperty(response.userId, 'routingGroup')).payload.map((p) => p.value);
    if (routingGroups.includes('DialedIn')) {
      return ['DI_MANAGER'];
    }
  }
  return roles;
}

/* eslint-disable no-shadow */
const actions = {
  // eslint-disable-next-line
  async setSsoToken({ }, token) {
    API.setSsoToken(token);
  },
  // eslint-disable-next-line
  async setRecaptchaToken({ }, token) {
    API.setRecaptchaToken(token);
  },
  async validateUserToken({ commit, dispatch }) {
    try {
      const response = await API.validateUserToken();
      if (response) {
        await signInWithCustomToken(getAuth(app), response.firebaseCustomToken);
        const userId = getAuth(app).currentUser.uid;
        console.log(`signed in to Firebase uid=${userId}`);
        await dispatch('getUserProfile', userId);
        const roles = await getRoles(response);
        commit(types.TOGGLE_IS_LOGGED_IN, true);
        commit(types.SET_ROLE, roles);
        commit(types.UPDATE_USER_ID, response.userid);
        if (response.email) {
          commit(types.UPDATE_EMAIL, response.email);
          commit(types.TOGGLE_NO_EMAIL, false);
        } else {
          commit(types.TOGGLE_NO_EMAIL, true);
        }
        commit(types.UPDATE_PLATFORM_TOKEN, response.token);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async login({ commit, dispatch }, payload) {
    try {
      const response = await API.login(payload);
      if (response) {
        await signInWithCustomToken(getAuth(app), response.firebaseCustomToken);
        const userId = getAuth(app).currentUser.uid;
        console.log(`signed in to Firebase uid=${userId}`);

        API.setSsoToken(response.token);
        await dispatch('getUserProfile', userId);
        const roles = await getRoles(response);
        commit(types.TOGGLE_IS_LOGGED_IN, true);
        commit(types.SET_ROLE, roles);
        commit(types.UPDATE_PLATFORM_TOKEN, response.token);
        if (response.userId) {
          commit(types.UPDATE_USER_ID, response.userId);
          VueCookies.set('userId', response.userId);
        }

        if (response.email) {
          commit(types.UPDATE_EMAIL, response.email);
          commit(types.TOGGLE_NO_EMAIL, false);
        } else {
          commit(types.TOGGLE_NO_EMAIL, true);
        }
        return response.token;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  async logout({ commit }) {
    await signOut(getAuth(app));
    commit(types.TOGGLE_IS_LOGGED_IN, false);
    commit(types.SET_ROLE, []);
    commit(types.UPDATE_PLATFORM_TOKEN, '');
    commit(types.UPDATE_USER_ID, null);
    commit(types.UPDATE_IS_ASL_AGENT, false);
    VueCookies.removeAll();
  },
  async getFirebaseIdToken() {
    return getAuth(app).currentUser.getIdToken();
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [types.TOGGLE_IS_LOGGED_IN](_state, payload) {
    _state.isLoggedIn = payload;
  },
  [types.SET_ROLE](_state, payload) {
    _state.role = payload.map((roleName) => ROLES[roleName]);
  },
  [types.UPDATE_SALES_ATTRIBUTION](_state, payload) {
    _state.salesAttribution = payload;
  },
  [types.UPDATE_VERIFICATION](_state, payload) {
    _state.verification = payload;
  },
  [types.UPDATE_PLATFORM_TOKEN](_state, payload) {
    _state.platformToken = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  ROLES,
};
