import * as types from '../mutation-types';

const state = {
  observers: { allowedAgents: [] },
  observersIds: [],
};

const getters = {
  observerAgents: (_state) => _state.observers?.allowedAgents || [],
  observerAgentsIds: (_state) => _state.observersIds || [],
};

const actions = {
};

/* eslint-disable no-param-reassign */
const mutations = {

  [types.REMOTE_CONFIG_OBSERVER](_state, val) {
    _state.observers = val;
  },
  [types.REMOTE_CONFIG_OBSERVERS_IDS](_state, val) {
    _state.observersIds = val;
  },
  
};

export default {
  state,
  getters,
  actions,
  mutations,
};
