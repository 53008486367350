import * as types from '../mutation-types';
import API from '../../api';
import AiraError from '../../utils/AiraError';

// initial state
const state = {
  giftCardOptions: {},
  giftCardSelected: '',
  gifteeFirstName: '',
  gifteeLastName: '',
  gifteeEmail: '',
  gifteeCountryCode: '+1',
  gifteePhoneNumber: '',
  gifteePhone: '',
  gifterName: '',
  gifterPersonalMessage: '',
};

// getters
const getters = {
  giftCardOptions: (_state) => _state.giftCardOptions,
  giftCardSelected: (_state) => _state.giftCardSelected,
  gifteeFirstName: (_state) => _state.gifteeFirstName,
  gifteeLastName: (_state) => _state.gifteeLastName,
  gifteeEmail: (_state) => _state.gifteeEmail,
  gifteeCountryCode: (_state) => _state.gifteeCountryCode,
  gifteePhoneNumber: (_state) => _state.gifteePhoneNumber,
  gifteePhone: (_state) => _state.gifteePhone,
  gifterName: (_state) => _state.gifterName,
  gifterPersonalMessage: (_state) => _state.gifterPersonalMessage,
};

/* eslint-disable no-shadow */
const actions = {
  async getGiftCardOptions({ dispatch }) {
    const response = await API.getGiftCardOptions();
    await dispatch('handleGiftCardOptions', response.options);
  },
  async handleGiftCardOptions({ commit }, options) {
    const parsedOptions = options;
    const currencyOptions = {
      USD: [],
      NZD: [],
      AUD: [],
      GBP: [],
    };
    await parsedOptions.forEach((option) => {
      const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: option.currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      });
      // eslint-disable-next-line
      option.text = `${option.name} — ${currencyFormatter.format(option.price / 100)}`;
      // eslint-disable-next-line
      option.value = option.code;
      currencyOptions[option.currency].push(option);
    });

    /* eslint-disable */
    // const newOptions = [
    //   { "value": "", "text": "Select Gift Amount", "disabled": true },
    //   { "value": "USD", "text": "United States and Canada", "options":  currencyOptions.USD },
    //   { "value": "NZD", "text": "New Zealand", "options":  currencyOptions.NZD },
    //   { "value": "AUD", "text": "United States and Canada", "options":  currencyOptions.AUD },
    // ];
    const newOptions = {
      "United States and Canada": [...currencyOptions.USD],
      "New Zealand": [...currencyOptions.NZD],
      "Australia": [...currencyOptions.AUD],
      "United Kingdom": [...currencyOptions.GBP],
    }
    /* eslint-enable */
    commit('SET_GIFT_CARD_OPTIONS', newOptions);
  },
  async getGiftCardPayload({ rootGetters }) {
    const countryCodeAlpha = rootGetters.countryCodeOptions.find((option) => option.value === rootGetters.gifteeCountryCode);

    const giftee = {
      firstName: rootGetters.gifteeFirstName,
      lastName: rootGetters.gifteeLastName,
      phoneNumber: rootGetters.gifteePhoneNumber,
      countryCode: countryCodeAlpha.country,
    };

    const gifter = {
      name: rootGetters.gifterName,
    };

    const orderlines = [];
    orderlines.push({
      productCode: rootGetters.giftCardSelected,
      productType: 'gift-card',
      quantity: 1,
    });

    const billingToken = {
      tokenId: rootGetters.recurlyCode,
    };

    const payload = {
      giftee,
      gifter,
      orderlines,
      billingToken,
    };

    return payload;
  },
  async getGiftCardOrderSummary({ dispatch, commit }) {
    try {
      const payload = await dispatch('getGiftCardPayload');
      const response = await API.getGiftCardOrderSummary(payload);

      commit(types.UPDATE_ORDER_SUMMARY, response.summary);
      commit(types.UPDATE_ORDER_TOTAL, response.todayTotalInCents);
      commit(types.UPDATE_ORDER_TOTAL_CURRENCY, response.currency);

      return response;
    } catch (error) {
      console.error(error);
      const err = new AiraError(error);
      commit(types.PUSH_ERRORS, [err.errorMessage]);
      return false;
    }
  },
  async postGiftCardOrder({ dispatch, commit }) {
    try {
      const payload = await dispatch('getGiftCardPayload');
      const response = await API.postGiftCardOrder(payload);

      return response;
    } catch (error) {
      const err = new AiraError(error);
      commit(types.PUSH_ERRORS, [err.errorMessage]);
      return false;
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [types.SET_GIFT_CARD_OPTIONS](_state, payload) {
    _state.giftCardOptions = payload;
    _state.giftCardSelected = '';
  },
  [types.SET_GIFT_CARD_SELECTED](_state, payload) {
    _state.giftCardSelected = payload;
  },
  [types.UPDATE_GIFTEE_FIRST_NAME](_state, payload) {
    _state.gifteeFirstName = payload;
  },
  [types.UPDATE_GIFTEE_LAST_NAME](_state, payload) {
    _state.gifteeLastName = payload;
  },
  [types.UPDATE_GIFTEE_EMAIL](_state, payload) {
    _state.gifteeEmail = payload;
  },
  [types.UPDATE_GIFTEE_COUNTRY_CODE](_state, payload) {
    _state.gifteeCountryCode = payload;
    _state.gifteePhone = `${payload}${_state.gifteePhoneNumber}`;
  },
  [types.UPDATE_GIFTEE_PHONE_NUMBER](_state, payload) {
    _state.gifteePhoneNumber = payload;
    _state.gifteePhone = `${_state.gifteeCountryCode}${payload}`;
  },
  [types.SET_GIFTEE_PHONE](_state, payload) {
    // PHONE = COUNTRYCODE + PHONENUMBER
    _state.gifteePhone = payload;
  },
  [types.UPDATE_GIFTER_NAME](_state, payload) {
    _state.gifterName = payload;
  },
  [types.UPDATE_GIFTER_PERSONAL_MESSAGE](_state, payload) {
    _state.gifterPersonalMessage = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
