import access from './access';
import address from './address';
import app from './app';
import gift from './gift';
import hardware from './hardware';
import joinaccount from './joinaccount';
import order from './order';
import plan from './plan';
import profile from './profile';
import password from './password';
import properties from './properties';
import referral from './referral';
import reseller from './reseller';
import signup from './signup';
import user from './user';
import remoteconfig from './remoteconfig';

export default {
  access,
  address,
  app,
  gift,
  hardware,
  order,
  plan,
  profile,
  properties,
  password,
  joinaccount,
  referral,
  reseller,
  signup,
  user,
  remoteconfig,
};
