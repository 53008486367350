import * as types from '../mutation-types';
import form from '../../data/form.json';
import API from '../../api';
import AiraError from '../../utils/AiraError';

const hoursToValues = new Map();
hoursToValues.set('0:00 am', 0);
hoursToValues.set('1:00 am', 1);
hoursToValues.set('2:00 am', 2);
hoursToValues.set('3:00 am', 3);
hoursToValues.set('4:00 am', 4);
hoursToValues.set('5:00 am', 5);
hoursToValues.set('6:00 am', 6);
hoursToValues.set('7:00 am', 7);
hoursToValues.set('8:00 am', 8);
hoursToValues.set('9:00 am', 9);
hoursToValues.set('10:00 am', 10);
hoursToValues.set('11:00 am', 11);
hoursToValues.set('12:00 pm', 12);
hoursToValues.set('1:00 pm', 13);
hoursToValues.set('2:00 pm', 14);
hoursToValues.set('3:00 pm', 15);
hoursToValues.set('4:00 pm', 16);
hoursToValues.set('5:00 pm', 17);
hoursToValues.set('6:00 pm', 18);
hoursToValues.set('7:00 pm', 19);
hoursToValues.set('8:00 pm', 20);
hoursToValues.set('9:00 pm', 21);
hoursToValues.set('10:00 pm', 22);
hoursToValues.set('11:00 pm', 23);

// initial state
const state = {
  daysOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  daysSelected: 0,
  startDate: '',
  startDay: 0,
  startHour: 0,
  startDayOptions29: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29],
  startDayOptions30: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
  startDayOptions31: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
  startMonth: 0,
  startMonthOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  startYear: 0,
  startYearOptions: [2020, 2021, 2022],
  startHourOptions: Array.from(hoursToValues.keys()),
  siteFirstName: '',
  siteLastName: '',
  siteStateOptions: form.stateOptions,
  siteAddress1: '',
  siteCountry: 'US',
  siteCity: '',
  siteState: '',
  siteZip: '',
  validFromTimestamp: new Date(),
};

// getters
const getters = {
  daysOptions: (_state) => _state.daysOptions,
  daysSelected: (_state) => _state.daysSelected,
  startDate: (_state) => _state.startDate,
  startDay: (_state) => _state.startDay,
  startDayOptions29: (_state) => _state.startDayOptions29,
  startDayOptions30: (_state) => _state.startDayOptions30,
  startDayOptions31: (_state) => _state.startDayOptions31,
  startMonth: (_state) => _state.startMonth,
  startMonthOptions: (_state) => _state.startMonthOptions,
  startYear: (_state) => _state.startYear,
  startYearOptions: (_state) => _state.startYearOptions,
  startHour: (_state) => _state.startHour,
  startHourOptions: (_state) => _state.startHourOptions,
  siteFirstName: (_state) => _state.siteFirstName,
  siteLastName: (_state) => _state.siteLastName,
  siteStateOptions: (_state) => _state.siteStateOptions,
  siteAddress1: (_state) => _state.siteAddress1,
  siteCity: (_state) => _state.siteCity,
  siteState: (_state) => _state.siteState,
  siteCountry: (_state) => _state.siteCountry,
  siteZip: (_state) => _state.siteZip,
};

/* eslint-disable no-shadow */
const actions = {
  getAccessPartnerPayload({ rootGetters }) {
    const payload = {
      orderlines: [
        {
          quantity: rootGetters.daysSelected,
          productCode: 'US-STE-001',
          productType: 'site-access',
        },
      ],
      billingToken: {
        tokenId: rootGetters.recurlyCode,
      },
      siteAddress: {
        validFromTimestamp: new Date(new Date(`${rootGetters.startMonth}/${rootGetters.startDay}/${rootGetters.startYear}`).setHours(hoursToValues.get(rootGetters.startHour))),
        address1: rootGetters.siteAddress1,
        city: rootGetters.siteCity,
        state: rootGetters.siteState,
        zip: rootGetters.siteZip,
        country: rootGetters.siteCountry,
      },
      account: {
        name: rootGetters.accountName,
        firstname: rootGetters.siteFirstName,
        lastname: rootGetters.siteLastName,
      },
    };

    return payload;
  },
  // eslint-disable-next-line
  async postAccessPartner({ }, payload) {
    try {
      return await API.postAccessPartner(payload);
    } catch (error) {
      console.error(error);
      throw (new AiraError(error));
    }
  },
  async postAccessPartnerOrderSummary({ dispatch, commit }) {
    try {
      const payload = await dispatch('getAccessPartnerPayload');
      const response = await API.postAccessPartnerOrderSummary(payload);

      commit(types.UPDATE_ORDER_SUMMARY, response.summary);
      commit(types.UPDATE_ORDER_TOTAL, response.todayTotalInCents);
      commit(types.UPDATE_ORDER_TOTAL_CURRENCY, response.currency);

      return response;
    } catch (error) {
      console.error(error);
      const err = new AiraError(error);
      commit(types.PUSH_ERRORS, [err.errorMessage]);
      return false;
    }
  },
  async postAccessPartnerOrder({ dispatch, commit }) {
    try {
      const payload = await dispatch('getAccessPartnerPayload');
      const response = await API.postAccessPartnerOrder(payload);

      return response;
    } catch (error) {
      const err = new AiraError(error);
      commit(types.PUSH_ERRORS, [err.errorMessage]);
      return false;
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [types.SET_DAYS_OPTIONS](_state, payload) {
    _state.daysOptions = payload;
    _state.daysSelected = '';
  },
  [types.UPDATE_START_DATE](_state, payload) {
    _state.startDate = payload;
  },
  [types.UPDATE_START_DAY](_state, payload) {
    _state.startDay = payload;
  },
  [types.UPDATE_START_MONTH](_state, payload) {
    _state.startMonth = payload;
  },
  [types.UPDATE_START_YEAR](_state, payload) {
    _state.startYear = payload;
  },
  [types.UPDATE_START_HOUR](_state, payload) {
    _state.startHour = payload;
  },
  [types.UPDATE_SITE_FIRST_NAME](_state, payload) {
    _state.siteFirstName = payload;
  },
  [types.UPDATE_SITE_LAST_NAME](_state, payload) {
    _state.siteLastName = payload;
  },
  [types.SET_DAYS_SELECTED](_state, payload) {
    _state.daysSelected = payload;
  },
  [types.UPDATE_SITE_ADDRESS1](_state, payload) {
    _state.siteAddress1 = payload;
  },
  [types.UPDATE_SITE_COUNTRY](_state, payload) {
    _state.siteCountry = payload;
    _state.siteState = '';
    switch (payload) {
      case 'US':
        _state.siteStateOptions = form.stateOptions;
        break;
      case 'CA':
        _state.siteStateOptions = form.canadaProvinceOptions;
        break;
      case 'AU':
        _state.siteStateOptions = form.australiaStateOptions;
        break;
      case 'NZ':
        _state.siteStateOptions = form.newZealandProvinceOptions;
        break;
      case 'UK':
        _state.siteStateOptions = form.unitedKingdomStateOptions;
        break;
      default:
        _state.siteStateOptions = [];
        break;
    }
  },
  [types.UPDATE_SITE_CITY](_state, payload) {
    _state.siteCity = payload;
  },
  [types.UPDATE_SITE_STATE](_state, payload) {
    _state.siteState = payload;
  },
  [types.UPDATE_SITE_ZIP](_state, payload) {
    _state.siteZip = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
