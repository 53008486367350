import * as types from '../mutation-types';
import { compare } from '../../utils';

// initial state
const state = {
  horizonOptIn: false,
  horizonSize: null,
  horizonTint: null,
  hardwareOptions: [],
  hardwareSelected: 'none',
  hardwareSelectedInfo: {},
};

// getters
const getters = {
  horizonOptIn: (_state) => _state.horizonOptIn,
  horizonSize: (_state) => _state.horizonSize,
  horizonTint: (_state) => _state.horizonTint,
  hardwareOptions: (_state) => _state.hardwareOptions,
  hardwareSelected: (_state) => _state.hardwareSelected,
  hardwareSelectedInfo: (_state) => _state.hardwareSelectedInfo,
};

/* eslint-disable no-shadow */
const actions = {
  // eslint-disable-next-line
  async handleHardware({}, plan) {
    let hardwareOptions = plan.hardwareAdjustments.concat(plan.hardwarePlans);
    if (hardwareOptions.length) {
      const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: hardwareOptions[0].currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      });
      hardwareOptions = hardwareOptions.sort(compare);
      /* eslint-disable */
      hardwareOptions.forEach((option) => {
        if (option.productType === 'adjustment') {
          option.value = option.code;
          option.type = (option.price > 0) ? 'Buy Now' : '';
          option.payment = (option.price > 0) ? 'One-time payment' : '';
          option.displayPrice = (option.price > 0) ? currencyFormatter.format(option.price / 100) : 'FREE';
        }
        if (option.productType === 'hardware') {
          option.name = option.planName;
          option.value = option.planCode;
          option.type = 'Rent-to-own';
          option.payment = `${option.billingCycles} monthly payments`;
          option.displayPrice = `${currencyFormatter.format(option.price / 100)}/mo`;
        }
      });
      hardwareOptions = hardwareOptions.concat({
        name: 'Continue without the Horizon Kit',
        value: 'none',
        price: 0,
        displayPrice: '',
      });
      return hardwareOptions;
    }
    return [];
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [types.SET_HORIZON_OPTIN](_state, payload) {
    _state.horizonOptIn = payload;
    if (!payload) _state.hardwareSelected = 'none';
  },
  [types.SET_HORIZON_SIZE](_state, payload) {
    _state.horizonSize = payload;
  },
  [types.SET_HORIZON_TINT](_state, payload) {
    _state.horizonTint = payload;
  },
  [types.SET_HARDWARE_OPTIONS](_state, payload) {
    _state.hardwareOptions = payload;
    _state.hardwareSelected = 'none';
    _state.sameBillingShipping = false;
    _state.hardwareSelectedInfo = {};
    _state.horizonOptIn = false;
  },
  [types.SET_HARDWARE_SELECTED](_state, payload) {
    _state.hardwareSelected = payload;
    if (payload === 'none') {
      _state.sameBillingShipping = false;
      _state.hardwareSelectedInfo = {};
      _state.horizonOptIn = false;
    } else {
      _state.hardwareSelectedInfo = _state.hardwareOptions.find(option => option.value === payload);
      _state.horizonOptIn = _state.hardwareSelectedInfo.name === 'Horizon Kit';
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
